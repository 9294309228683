import { ListFilter } from "lucide-react";
import React, { useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import "./ReactTable.scss";

export const ReactTable = ({ columns, data, title, classname = "" }) => {
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    setAllFilters,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
      },
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const toggleColumnFilters = () => {
    setShowColumnFilters(!showColumnFilters);
    setAllFilters([]);
  };

  return (
    <div className={`${classname}`}>
      <div className={`react-table-wrapper`}>
        <div className="react-table-header w-100">
          <div className="react-table-title">
            <h4 className="mr-10 w-100">{title ? title : ""}</h4>
          </div>
          {data && data.length > 0 ? (
            <div className="react-table-filters">
              {/* Global Filter */}
              <div className="w-100">
                <Form.Control
                  type="text"
                  value={globalFilter || ""}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Search..."
                />
              </div>
              <div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Filter</Tooltip>}
                >
                  <div
                    onClick={toggleColumnFilters}
                    className={`filter-icon-wrapper ${
                      showColumnFilters ? "filter-enabled" : ""
                    }`}
                  >
                    <ListFilter className="cursor-pointer" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          ) : null}
        </div>

        <div className="table-scroller">
          <table className="default-table">
            {data && data.length > 0 ? (
              <>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <>
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            className={column.className}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                      {showColumnFilters && (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps()}
                              className={`$${column.className} active-filters-row`}
                            >
                              <div>
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </div>
                            </th>
                          ))}
                        </tr>
                      )}
                    </>
                  ))}
                </thead>
                {page?.length > 0 ? (
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          className={row.depth ? "subrows" : "normal"}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => (
                            <td
                              {...cell.getCellProps({
                                className: cell.column.className,
                              })}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td
                        colSpan={columns.length}
                        className="table-data-not-found"
                      >
                        No Data Found.
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={columns.length} className="table-data-not-found p-3">
                    No Data Available.
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* Pagination */}
      {data && data.length > 0 ? (
        <div className="react-table-pagination">
          <div className="table-pagination-content">
            <button
              className="pagination-prev"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              Previous
            </button>{" "}
            <span className="current-page-count">{pageIndex + 1}</span>
            <button
              className="pagination-next"
              onClick={nextPage}
              disabled={!canNextPage}
            >
              Next
            </button>{" "}
          </div>
        </div>
      ) : null}
    </div>
  );
};

// Default filter UI
const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <Form.Control
      type="text"
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder="Filter..."
      className="mb-2"
    />
  );
};
