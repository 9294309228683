import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { CSVLink } from "react-csv";
import Select from "react-select";
import ViewAthleteTransaction from "../../constant/AthleteTransaction";
import MainPagetitle from "../../layouts/MainPagetitle";
import { ReactTable } from "../Common/ReactTable/ReactTable";
import "../Common/ReactTable/ReactTable.scss";

const AthleteReport = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [statusPriority, setStatusPriority] = useState([]);
  const [data, setData] = useState([]);
  const [sports, setSports] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedStateIds, setSelectedStateIds] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [maxAge, setMaxAge] = useState(0);
  const [filters, setFilters] = useState({
    age: "",
    isPublish: "",
    gender: "",
    isSubscription: "Active",
    residentialState: "",
    city: "",
    sportsId: "",
    startDate: "",
    endDate: "",
  });
  const [columns, setColumns] = useState([]);
  const [activePag, setActivePag] = useState(() => {
    const storedPage = sessionStorage.getItem("activePag");
    return storedPage ? parseInt(storedPage) : 0;
  });
  const sort = 10;

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
    fetchTableData({ ...filters, [name]: value });
  };

  const handleSelectChange = (selected, { name }) => {
    let value = "";

    if (selected) {
      if (Array.isArray(selected)) {
        value = selected.map((option) => option.value).join(",");
      } else {
        value = selected.value;
      }
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    fetchTableData({ ...filters, [name]: value });
  };

  useEffect(() => {
    fetchStates();
    fetchSports();
    fetchTableData();
    fetchAge();
  }, []);
  const handleDateChange = (date, name) => {
    setFilters((prev) => ({ ...prev, [name]: date }));
    fetchTableData({ ...filters, [name]: date });
  };
  const fetchAge = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getsettings/1`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      const fromAge = parseInt(response.data.data.fromAge);
      const toAge = parseInt(response.data.data.toAge);

      // const options = [];
      // for (let i = fromAge; i <= toAge; i++) {
      //   options.push({ value: i, label: i.toString() });
      // }
      setMaxAge(toAge);
      const options = [
        { value: "1", label: "7-8" },
        { value: "2", label: "9-10" },
        { value: "3", label: "11-12" },
        { value: "4", label: "13-14" },
        { value: "5", label: "15-16" },
        { value: "6", label: "17-18" },
        { value: "7", label: "18+" },
      ];
      setAgeOptions(options);
    } catch (error) {
      console.error("Error fetching age:", error);
    }
  };

  useEffect(() => {
    if (selectedStateIds.length > 0) {
      fetchCitiesByState(
        selectedStateIds.map((state) => state.value).join(",")
      );
    } else {
      setCities([]);
    }
  }, [selectedStateIds]);

  const fetchTableData = (updatedFilters = filters) => {
    const apiUrl = `${process.env.REACT_APP_REPORTS_API_URI}/athletepurchasereport`;
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(apiUrl, {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: updatedFilters,
        })
        .then((response) => {
          setTableData(response.data.transactionHistories);
          setStatusPriority(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      console.error("Token not found in local storage");
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallstates`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: token,
          },
        }
      );
      setStates(response.data.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCitiesByState = async (stateIds) => {
    try {
      const token = localStorage.getItem("token");
      setCities([]);
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallcities`,
        {
          params: { stateId: stateIds },
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: token,
          },
        }
      );
      setCities(response.data.citiesData);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchSports = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_SPORTS_API_URI}/getallsports`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: token,
          },
        }
      );
      setSports(response.data.data);
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  const [test, setTest] = useState(0);

  useEffect(() => {
    setData(document.querySelectorAll("#employee-tbl_wrapper tbody tr"));
  }, [test]);

  const onClickPage = (page) => {
    setActivePag(page);
    sessionStorage.setItem("activePag", page);
  };

  const pagination = useMemo(
    () =>
      Array.from(
        { length: Math.ceil(data.length / sort) },
        (_, index) => index + 1
      ),
    [data]
  );

  const handleReset = () => {
    const defaultFilters = {
      age: "",
      isPublish: "",
      gender: "",
      isSubscription: "",
      residentialState: "",
      city: "",
      sportsId: "",
      startDate: "",
      endDate: "",
    };
    setFilters(defaultFilters);
    setSelectedStateIds([]);
    setSelectedCities([]);
    fetchTableData(defaultFilters);
  };

  const viewclub = useRef();

  const generateCSVData = () => {
    return tableData.map((user) => ({
      firstName: user.user.firstName,
      lastName: user.user.lastName,
      parentFirstName: user.user.athletes[0]?.parentFirstName || "",
      parentLastName: user.user.athletes[0]?.parentLastName || "",
      age: user.user.athletes[0]?.age || "",
      gender: user.user.athletes[0]?.gender || "",
      sports:
        user.user.athletes[0]?.sportsData
          .map((sport) => sport.sportName)
          .join(", ") || "",
      city: user.user.athletes[0]?.cityData?.name || "",
      residentialState: user.user.athletes[0]?.residentialStateData?.name || "",
      email: user.user.email || "",
      subscriptionName: user.subscriptionName || "",
      startDate: user.startDate || "",
      endDate: user.endDate || "",
      renewDate:
        new Date(
          new Date(user.endDate).setDate(new Date(user.endDate).getDate() + 1)
        ).toLocaleDateString("en-US") || "",
      subscriptionMonth: `${user.subscriptionMonth} Months` || "",
      totalAmount: user.totalAmount || "",
      discount: user.discount || "",
      processingTax: user.processingTax || "",
      convenienceTax: user.convenienceTax || "",
      serviceTax: user.serviceTax || "",
    }));
  };
  const formatDate = (date) => {
    if (!date) return "";
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const reqColumns = [
      {
        Header: "First Name",
        accessor: (row) => row.user.firstName,
        className: "w-150",
        Cell: (localProps) => (
          <div className="">
            <p className="w-150 text-wrap">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Last Name",
        accessor: (row) => row.user.lastName,
        className: "w-150",
        Cell: (localProps) => (
          <div className="">
            <p className="w-150 text-wrap">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Parent First Name",
        accessor: (row) => row.user.athletes[0]?.parentFirstName,
        className: "w-150",
        Cell: (localProps) => (
          <div className="">
            <p className="w-150 text-wrap">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Parent Last Name",
        accessor: (row) => row.user.athletes[0]?.parentLastName,
        className: "w-150",
        Cell: (localProps) => (
          <div className="">
            <p className="w-150 text-wrap">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Sports",
        accessor: (row) =>
          row.user.athletes[0]?.sportsData
            ?.map((sport, sportIndex) => {
              const sportName = sport.sportName;
              const words = sportName.split(" ");
              const wrappedSportName = words.reduce((result, word, index) => {
                if (index > 0 && index % 2 === 0) {
                  return `${result},\n${word}`;
                }
                return result === "" ? word : `${result} ${word}`;
              }, "");
              return wrappedSportName;
            })
            .join(","),
        className: "w-150",
        Cell: (localProps) => <div className="w-150">{localProps.value}</div>,
      },
      {
        Header: "Age",
        accessor: (row) => row.user.athletes[0]?.age,
        className: "w-150",
        Cell: (localProps) => (
          <div className="">
            <p className="w-60px">{localProps.value}</p>
          </div>
        ),
      },
      {
        Header: "Gender",
        accessor: (row) => row.user.athletes[0]?.gender,
        className: "w-150",
        Cell: (localProps) => <p className="w-150">{localProps.value}</p>,
      },
      {
        Header: "Phone Number",
        accessor: (row) => row.user.athletes[0]?.parentPhone,
        className: "w-150",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Residential City",
        accessor: (row) => row.user.athletes[0]?.cityData?.name,
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Residential State",
        accessor: (row) => row.user.athletes[0]?.residentialStateData?.name,
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Email",
        accessor: (row) => row.user?.email,
        className: "mw-250",
        Cell: (localProps) => (
          <p className="text-wrap text-trancate">{localProps.value}</p>
        ),
      },
      {
        Header: "Subscription Plan",
        accessor: "subscriptionName",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value || "-"}</p>,
      },
      {
        Header: "Subscription Start Date",
        accessor: "startDate",
        className: "mw-250",
        Cell: (localProps) => (
          <p className="">{formatDate(localProps.value) || "-"}</p>
        ),
      },
      {
        Header: "Subscription End Date",
        accessor: "endDate",
        className: "mw-250",
        Cell: (localProps) => (
          <p className="">{formatDate(localProps.value) || "-"}</p>
        ),
      },
      {
        Header: "Subscription Renewal Date",
        accessor: (row) =>
          new Date(
            new Date(row.endDate).setDate(new Date(row.endDate).getDate() + 1)
          ).toLocaleDateString("en-US"),
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Subscription Duration",
        accessor: "subscriptionMonth",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Subscription Amount",
        accessor: "subtotalAmount",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Discount Amount",
        accessor: "discount",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Processing Fee",
        accessor: "processingTax",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Convenience Fee",
        accessor: "convenienceTax",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Service Fee",
        accessor: "serviceTax",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Tax",
        accessor: "taxAmount",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Total Subscription Amount",
        accessor: "totalAmount",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Payment Date",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Payment Transaction ID",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Referral Code",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Coach First Name",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Coach Last Name",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Coach Email",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Coach Primary Sport",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Coach Speciality",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
      {
        Header: "Promo Code",
        accessor: "",
        className: "mw-250",
        Cell: (localProps) => <p className="">{localProps.value}</p>,
      },
    ];
    setColumns(reqColumns);
  }, []);

  return (
    <>
      <div className="col-xl-12 text-end mt-3 d-flex">
        <div className="col-xl-10 text-end mb-3">
          <MainPagetitle
            mainTitle="Athlete Report"
            pageTitle="Athlete Report"
            parentTitle="Home"
          />
        </div>
        <div className="col-xl-2 text-end mb-3">
          <CSVLink
            data={generateCSVData()}
            filename={`athlete_report_${new Date().toLocaleDateString()}.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            Export to CSV
          </CSVLink>
        </div>
      </div>
      <div className="">
        <div className="row">
          <div className="col-xl-12">
            <div className="filter cm-content-box box-primary">
              <Collapse in={open}>
                <div className="cm-content-body form excerpt">
                  <div className="card-body">
                    <div className="row filter-row">
                      <div className="col-xl-2 col-sm-6 mb-3">
                        <Select
                          options={ageOptions}
                          value={ageOptions.find(
                            (option) => option.value === filters.age
                          )}
                          onChange={(selected) =>
                            handleSelectChange(selected, { name: "age" })
                          }
                          isMulti
                          placeholder="Select Age"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6 mb-3">
                        <select
                          className="form-control mb-xl-0 mb-3"
                          name="gender"
                          value={filters.gender}
                          onChange={handleFilterChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Others</option>
                        </select>
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          options={sports.map((sport) => ({
                            value: sport.id,
                            label: sport.sportName,
                          }))}
                          value={
                            filters.sportsId
                              ? filters.sportsId.split(",").map((id) => ({
                                  value: id,
                                  label: sports.find(
                                    (sport) => sport.id === parseInt(id)
                                  )?.sportName,
                                }))
                              : []
                          }
                          onChange={(selected) =>
                            handleSelectChange(selected, { name: "sportsId" })
                          }
                          isSearchable
                          isMulti
                          placeholder="Select Sport"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          isSearchable
                          options={states.map((state) => ({
                            value: state.id,
                            label: state.name,
                          }))}
                          className="custom-react-select"
                          name="residentialState"
                          value={selectedStateIds}
                          onChange={(selected) => {
                            setSelectedStateIds(selected);
                            handleSelectChange(selected, {
                              name: "residentialState",
                            });
                          }}
                          isMulti
                          placeholder="Select State"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <Select
                          isSearchable
                          options={cities.map((city) => ({
                            value: city.id,
                            label: city.name,
                          }))}
                          className="custom-react-select"
                          name="city"
                          value={selectedCities}
                          onChange={(selected) => {
                            setSelectedCities(selected);
                            handleSelectChange(selected, { name: "city" });
                          }}
                          isMulti
                          placeholder="Select City"
                        />
                      </div>
                      {/* <div className="col-xl-2 col-sm-6">
                        <DatePicker
                          selected={filters.startDate}
                          onChange={(date) => handleDateChange(date, 'startDate')}
                          className="form-control mb-xl-0 mb-3"
                          placeholderText="Start Date"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                      <div className="col-xl-2 col-sm-6">
                        <DatePicker
                          selected={filters.endDate}
                          onChange={(date) => handleDateChange(date, 'endDate')}
                          className="form-control mb-xl-0 mb-3"
                          placeholderText="End Date"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div> */}
                      <div className="col-xl-2 col-sm-6">
                        <div className="filter-btn">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleReset}
                          >
                            <i className="bi bi-arrow-clockwise me-1"></i> Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>

          {/* <div id="employee-tbl_wrapper" className="table-responsive no-footer">
            <table
              id="empoloyees-tblwrapper"
              className="table ItemsCheckboxSec dataTable no-footer mb-0"
            >
              <thead>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Parent First Name </th>
                <th>Parent Last Name</th>
                <th>sports</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Phone Number</th>
                <th>Resendtial City</th>
                <th>Resendtial State</th>
                <th>Email</th>
                <th>Subscription Plan</th>
                <th>Subscription Start Date</th>
                <th>Subscription End Date</th>
                <th>Subscription Renewal Date</th>
                <th>Subscription Duration </th>
                <th>Subscription Amount </th>
                <th>Discount Amount </th>
                <th>Processing Fee</th>
                <th>Convenience Fee</th>
                <th>Service Fee</th>
                <th>Total Subscription Amount</th>
                <th>Payment Date</th>
                <th>Payment Transaction ID</th>
                <th>Referral Code</th>
                <th>Coach First Name</th>
                <th>Coach Last Name</th>
                <th>Coach Email</th>
                <th>Coach Primary Sports</th>
                <th>Coach Speciality</th>
                <th>Promo Code</th>
              </thead>
              <tbody>
                {tableData.map((user, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <span>{user.user.firstName}</span>{" "}
                    </td>
                    <td>
                      {" "}
                      <span>{user.user.lastName}</span>
                    </td>
                    <td>
                      {" "}
                      <span>{user.user.athletes[0]?.parentFirstName} </span>
                    </td>
                    <td>
                      {" "}
                      <span>{user.user.athletes[0]?.parentLastName}</span>
                    </td>
                    <td>
                      <span style={{ whiteSpace: "pre-wrap" }}>
                        {user.user.athletes[0]?.sportsData
                          ?.map((sport, sportIndex) => {
                            const sportName = sport.sportName;
                            const words = sportName.split(" ");
                            const wrappedSportName = words.reduce(
                              (result, word, index) => {
                                if (index > 0 && index % 2 === 0) {
                                  return `${result},\n${word}`;
                                }
                                return result === ""
                                  ? word
                                  : `${result} ${word}`;
                              },
                              ""
                            );
                            return wrappedSportName;
                          })
                          .join(",")}
                      </span>
                    </td>

                    <td>
                      {" "}
                      <span>{user.user.athletes[0]?.age}</span>
                    </td>
                    <td>
                      {" "}
                      <span>{user.user.athletes[0]?.gender}</span>
                    </td>
                    <td>
                      {" "}
                      <span>{user.user.athletes[0]?.parentPhone}</span>
                    </td>
                    <td>
                      {" "}
                      <span>{user.user.athletes[0]?.cityData?.name}</span>
                    </td>
                    <td>
                      {" "}
                      <span>
                        {user.user.athletes[0]?.residentialStateData?.name}
                      </span>
                    </td>
                    <td>
                      {" "}
                      <span>{user.user.email}</span>
                    </td>

                    <td>
                      {" "}
                      <span>{user.subscriptionName}</span>
                    </td>
                    <td className="text-center">
                      <span>{formatDate(user.startDate)}</span>
                    </td>
                    <td className="text-center">
                      <span>{formatDate(user.endDate)}</span>
                    </td>

                    <td className="text-center">
                      {" "}
                      <span>
                        {" "}
                        {new Date(
                          new Date(user.endDate).setDate(
                            new Date(user.endDate).getDate() + 1
                          )
                        ).toLocaleDateString("en-US")}
                      </span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span>{user.subscriptionMonth} Months</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span>{user.totalAmount}</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span>{user.discount}</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span>{user.processingTax}</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span>{user.convenienceTax}</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span>{user.serviceTax}</span>
                    </td>
                    <td className="text-center">
                      {" "}
                      <span>{user.totalAmount}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
          <div className="row px-4">
            <ReactTable
              columns={columns}
              data={tableData || []}
              title={"Athlete Reports"}
            />
          </div>
        </div>
      </div>
      <ViewAthleteTransaction ref={viewclub} Title="Transaction Details" />
    </>
  );
};

export default AthleteReport;
