import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert";
import NoImage from "../../../../images/no-image.jpg";
import TextEditor from "../../Common/TextEditor";

const WelcomeConnect = () => {
  const [title, setTitle] = useState("");
  const [sectionContent, setSectionContent] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [changedBanners, setChangedBanners] = useState([]);
  const [hideSection, setHideSection] = useState(true);
  const [localImage, setLocalImage] = useState(null)

  const fetchPageData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/1`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const cmssections = response.data.data.cmssections;
      const section = cmssections.find((section) => section.id === 2);
      if (section) {
        setTitle(section.title || "");
        setSectionContent(section.description || "");
        setShortDescription(section.shortDescription || "");
        setHideSection(section.isPublish)
      }

      const cmsgalleries = response.data.data.cmsgalleries;
      setGalleryImages(cmsgalleries);
      const banner = cmsgalleries.find((item) => item.cmssectionId === 2);
      if (banner) {
        setFile(banner.fileLocation);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updatePageData = async (title, description, shortDescription, isPublished) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const fetchedData = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/1`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const cmssections = fetchedData.data.data.cmssections;
      const sectionToUpdate = cmssections.find((section) => section.id === 2);

      if (!sectionToUpdate) {
        console.error("Section with id 2 not found");
        return;
      }

      sectionToUpdate.title = title;
      sectionToUpdate.description = description;
      sectionToUpdate.shortDescription = shortDescription;

      if (!sectionToUpdate) {
        console.error("Section with id 2 not found");
        return;
      }
      sectionToUpdate.title = title;
      sectionToUpdate.description = description;
      sectionToUpdate.shortDescription = shortDescription;
      sectionToUpdate.isPublish= isPublished
      const updatedData = {
        ...fetchedData.data,
        data: {
          ...fetchedData.data.data,
          cmssections: [sectionToUpdate],          
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updatepage`,
        updatedData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        swal("Update successful", { icon: "success" });
        fetchPageData()
      } else {
        swal("Failed to update", { icon: "error" });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      swal("Failed to update", { icon: "error" });
    }
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  const handleSave = () => {
    updateExactFiles(changedBanners);
    updatePageData(title, sectionContent, shortDescription, hideSection);
  };

  const updateExactFiles = async (changedBanners) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      console.log("Image", changedBanners);

      const formData = new FormData();
      formData.append(
        "cmsgalleries",
        JSON.stringify(
          changedBanners.map((banner) => ({
            id: banner.id,
            cmId: banner.cmId,
          }))
        )
      );
      changedBanners.forEach((banner) => {
        formData.append("fileLocation", banner.fileLocation);
      });
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updateexactfiles`,
        formData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Exact files updated successfully");
        fetchPageData()
      } else {
        console.error("Failed to update exact files");
      }
    } catch (error) {
      console.error("Error updating exact files:", error);
    }
  };

  const fileHandler = async (event, index) => {
    const selectedFile = event.target.files[0];
    console.log("Selected File:", selectedFile);

    const image = new Image();
    image.src = URL.createObjectURL(selectedFile);

    image.onload = async function () {
      // if (
      //   image.width >= 570 &&
      //   image.width <= 580 &&
      //   image.height >= 390 &&
      //   image.height <= 400
      // ) {
        const reader = new FileReader();

        reader.onload = (e) => {
          setFile(URL.createObjectURL(selectedFile))
         
          const newBanners = [...files];
          newBanners[index] = {
            ...newBanners[index],
            fileLocation: selectedFile,
          };
          setFiles(newBanners);

          const newChangedBanners = [...changedBanners];
          const existingBanner = newChangedBanners.find(
            (banner) => banner.id === newBanners[index].id
          );

          if (existingBanner) {
            existingBanner.fileLocation = selectedFile;
          } else {
            const matchedBanner = galleryImages.find(
              (item) => item.cmssectionId === 2
            );
            if (matchedBanner) {
              newChangedBanners.push({
                id: matchedBanner.id,
                cmId: matchedBanner.cmId,
                fileLocation: selectedFile,
              });
            }
          }

          setChangedBanners(newChangedBanners);
        };

        reader.readAsBinaryString(selectedFile);
      // } else {
      //   swal(
      //     "Invalid Image Dimensions",
      //     "Please select an image with width between 570 to 580 and height between 390 to 400 pixels.",
      //     "error"
      //   );
      // }
    };
    const objectURL = URL.createObjectURL(selectedFile);
    image.src = objectURL;
  };

  const handleToggleSection = (event) => {
      setHideSection(event.target.checked)
      updatePageData(title, sectionContent, shortDescription, event.target.checked)
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="h-auto">
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-11">
                    <div className="content-title d-flex">
                      <h4 className="d-flex align-items-center">
                        Welcome Section
                      </h4>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id={`tooltip-dimensions-BANNER`}>
                            Image dimensions - 390-400px in height and 570-580px
                            in width.
                            <br></br>File Size should be under 400KB
                          </Tooltip>
                        }
                      >
                        <div className="d-flex  mx-2 align-items-center">
                          <i class="bi bi-question-circle"></i>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="col-1">
                    <div className="content-title border-bot">
                      <Form.Check
                        type="switch"
                        id={`welcome-section`}
                        checked={hideSection}
                        onChange={handleToggleSection
                        }
                      />
                    </div>
                  </div>
                </div>

                {hideSection && (
                  <>
                    <div className="  cm-content-body publish-content form excerpt">
                      <div className="card-body">
                        <div className="avatar-upload d-flex align-items-center justify-content-center">
                          <div className="col-12 col-xl-6">
                            <div className="">
                              <div className="avatar-preview">
                                <div
                                  id="imagePreview"
                                  style={{
                                    backgroundImage: file
                                      ? `url(${file})`
                                      :`url(${NoImage})`,
                                  }}
                                  width={200}
                                ></div>
                              </div>
                            </div>
                            <div className="change-btn d-flex align-items-center flex-wrap justify-content-center">
                              <input
                                type="file"
                                onChange={(event) => fileHandler(event, 0)}
                                id="imageUpload"
                                className="form-control d-none"
                                accept="image/*"
                                // onClick={(event) => setFile(event.target.value)}
                              />
                              <label
                                for="imageUpload"
                                className="btn btn-secondary light ms-0"
                              >
                                +
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <TextEditor
                      content={title}
                      setContent={setTitle}
                      title={"Title"}
                    />
                    <TextEditor
                      content={shortDescription}
                      setContent={setShortDescription}
                      title={"Short Description"}
                    />
                    <TextEditor
                      content={sectionContent}
                      setContent={setSectionContent}
                      title={"Description"}
                    />
                    <div className="content-title border-bot d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-primary my-2 w-90px"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeConnect;
