import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=YOUR_API_KEY`,
        postData,
    );
}

export async function login(email, password) {
    try {
      const postData = {
        email,
        password,
      };
      
      const response = await axios.post(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/login`,
        postData
      );
      return response;
      
    //   const { roleId } = response?.data?.user; // Assuming `roleId` is part of the API response
    //   console.log("here login roleID",roleId)
      
    //   if (roleId === 1) {
    //     // Allow further actions
    //     return response;
    //   } else {
        
    //     throw new Error('Access denied. Only admins are allowed.');
       
    //   }
    } catch (error) {
      // Handle errors (e.g., invalid credentials, server error)
      console.error(error.message);
      throw error;
    }
  }

export function formatError(errorResponse) {
    console.log("errorResponse===========",errorResponse);
    const message = errorResponse?.error?.message || errorResponse?.message;

    switch (message) {
        case 'EMAIL_EXISTS':            
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';
        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
        return;
    }

    const tokenDetails = JSON.parse(tokenDetailsString);
    const expireDate = new Date(tokenDetails.expireDate);
    const todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
    return !!localStorage.getItem('userDetails');
}
