import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert";
import NoImage from "../../../../images/no-image.jpg";
import PageTitle from "../../../layouts/PageTitle";


const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [localImages, setLocalImages] = useState([]);
  const [changedBanners, setChangedBanners] = useState([]);
  const [error, setError] = useState("");
  const [hideSection, setHideSection] = useState(true);

  const fetchPageData = async (setBanners) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
  
      const response = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/2`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
  
      const cmsgalleries = response.data.data.cmsgalleries;
      const banners = cmsgalleries.filter((item) => item.cmssectionId === 11);
      setBanners(banners);
      const section = response.data.data.cmssections?.find(each => each.id === 11)
      if(section){
        setHideSection(section.isPublish)
      }
      console.log(banners);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const updatePageData = async (isPublished) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
  
      const fetchedData = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/2`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
  
      const updatedCmssections = fetchedData?.data?.data?.cmssections?.map(each => each.id === 11 ? {...each, isPublish: isPublished}: each)
      const updatedData = {
        ...fetchedData.data,
        data: {
          ...fetchedData.data.data,
          cmssections :updatedCmssections
        },
      };
      console.log("UpdatedData", updatedData);
  
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updatepage`,
        updatedData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        swal("Update successful", { icon: "success" });
        fetchPageData(setBanners)
      } else {
        swal("Failed to update", { icon: "error" });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      swal("Failed to update", { icon: "error" });
    }
  };
  
  const updateExactFiles = async (changedBanners) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      console.log("Image", changedBanners);
  
      const formData = new FormData();
      formData.append(
        "cmsgalleries",
        JSON.stringify(
          changedBanners.map((banner) => ({
            id: banner.id,
            cmId: banner.cmId,
          }))
        )
      );
      changedBanners.forEach((banner) => {
        formData.append("fileLocation", banner.fileLocation);
      });
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updateexactfiles`,
        formData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 200) {
        console.log("Exact files updated successfully");
      } else {
        console.error("Failed to update exact files");
      }
    } catch (error) {
      console.error("Error updating exact files:", error);
    }
  };
  

  const newFilesHandler = (event) => {
    const files = event.target.files[0];
    if(files){
      const img = new Image();

      img.onload = () => {
        const { width, height } = img;
          const reader = new FileReader();

          reader.onload = (e) => {
            const newLocalImages = Array(6).fill(URL.createObjectURL(files));            
            setLocalImages(newLocalImages);

            const newBanners = Array(6).fill({fileLocation: files})
            setBanners(newBanners);
            setChangedBanners(newBanners);
          }
          reader.readAsBinaryString(files); // Read file as binary string if needed        
      };

      const objectURL = URL.createObjectURL(files);
      img.src = objectURL;
    }
  }

  const fileHandler = (event, index) => {
    const selectedFile = event.target.files[0];
    console.log("selectedFile", selectedFile);
    if (selectedFile) {
      const maxFileSize = 500 * 1024;
      // if (selectedFile.size > maxFileSize) {
      //   swal("File size should be under 500KB.", { icon: "error" });
      //   return;
      // }
      
      const img = new Image();

      img.onload = () => {
        const { width, height } = img;

        // if (height >= 700 && height <= 720 && width >= 1920 && width <= 2000) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const newLocalImages = [...localImages];
          newLocalImages[index] = URL.createObjectURL(selectedFile);
          setLocalImages(newLocalImages);

          const newBanners = [...banners];
          newBanners[index] = {
            ...newBanners[index],
            fileLocation: selectedFile,
          };
          setBanners(newBanners);

          const newChangedBanners = [...changedBanners];
          if (
            !newChangedBanners.some(
              (banner) => banner.id === newBanners[index].id
            )
          ) {
            newChangedBanners.push(newBanners[index]);
          }
          setChangedBanners(newChangedBanners);
          console.log("updated", newChangedBanners);
        };

        reader.readAsBinaryString(selectedFile); // Read file as binary string if needed
        // } else {
        //   swal(
        //     "Image dimensions should be between 700-720px in height and 1920-2000px in width.",
        //     { icon: "error" }
        //   );
        // }
      };

      const objectURL = URL.createObjectURL(selectedFile);
      img.src = objectURL;
    }
  };

  useEffect(() => {
    fetchPageData(setBanners);
  }, []);

  const handleSave = () => {
    updatePageData(hideSection, banners, changedBanners);
    updateExactFiles(changedBanners);
  };

  const handleToggleSection = (event) => {
    setHideSection(event.target.checked)
    updatePageData(event.target.checked, banners, changedBanners)
  }

  const getImageUrl = (banner, localImage) => {
    if (localImage) {
      return localImage;
    } else if (banner) {
      return banner.fileLocation;
    } else {
      return NoImage;
    }
  };

  return (
    <>
      <PageTitle activeMenu="About Page" motherMenu="CMS" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 ">
            <div className="col-xl-12 cm-content-body publish-content form excerpt">
              <div className="row">
                <div className="col-11">
                  <div className="content-title d-flex">
                    <h4 className="d-flex align-items-center">Banner</h4>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-dimensions-BANNER`}>
                          Image dimensions - 700-720px in height and 1920-2000px
                          in width.
                          <br></br>File Size should be under 500KB
                        </Tooltip>
                      }
                    >
                      <div className="d-flex  mx-2 align-items-center">
                        <i class="bi bi-question-circle"></i>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="col-1">
                  <div className="content-title border-bot">
                    <Form.Check
                      type="switch"
                      id={`testimonial-section`}
                      checked={hideSection}
                      onChange={handleToggleSection}
                    />
                  </div>
                </div>
              </div>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              {hideSection && (
                <>
                  <div className="card-body">
                    <div className="avatar-upload d-flex align-items-center">
                      <div className=" col-xl-12 col-lg-12 col-md-12">
                        <div className="avatar-preview d-flex">
                          {banners?.length > 0 ?banners.map((banner, index) => (
                            <div
                              key={index}
                              className="preview-image"
                              style={{
                                backgroundImage: `url(${getImageUrl(
                                  banner,
                                  localImages[index]
                                )})`,
                                marginRight: "20px",
                              }}
                            ></div>
                          )) : <>
                          {Array(1).fill('').map(each => <div                              
                            className="preview-image h-120"
                            style={{
                             backgroundImage: `url(${NoImage})`,
                              marginRight: "20px",
                            }}
                          ></div>)}
                        </>}
                        </div>
                        <div className="change-btn d-flex align-items-center flex-wrap justify-content-around">
                          {banners.map((_, index) => (
                            <div
                              key={index}
                              className="image-input"
                              style={{ marginBottom: "10px" }}
                            >
                              <input
                                type="file"
                                onChange={(e) => fileHandler(e, index)}
                                id={`Banner${index}`}
                                className="form-control d-none"
                                accept="image/*"
                              />
                              <label
                                htmlFor={`Banner${index}`}
                                className="btn btn-primary ms-0"
                                style={{ marginRight: "10px" }}
                              >
                                +
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center w-100">
                    {banners?.length > 0 ? <button
                        type="button"
                        className="btn btn-primary  my-2 w-90px"
                        onClick={handleSave}
                      >
                        Save
                      </button> : 
                         <div                         
                         className="image-input"
                         style={{ marginBottom: "10px" }}
                       >
                         <input
                           type="file"
                           onChange={(e) => newFilesHandler(e)}
                           id={'initialBanners'}
                           className="form-control d-none"
                           accept="image/*"
                         />
                         <label
                           htmlFor={`initialBanners`}
                           className="btn btn-secondary light ms-0"
                           style={{ marginRight: "10px" }}
                         >
                           +
                         </label>
                       </div>
                      }
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
