/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Pencil, SquareArrowOutUpRight, Trash2 } from "lucide-react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import AthleteProfile from "../../api/userManager/athleteProfile";
import noimage from "../../images/noprofile/noprofile2.jpg";
import MediaPopup from "../components/Common/MediaPopup";
import TextEditor from "../components/Common/TextEditor";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()

    .max(15, "Too Long!")
    .required("Required"),
  lastName: Yup.string()

    .max(15, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),

  profileImg: Yup.string(), // Assuming it's a string for image URL
  age: Yup.number()
    .integer("Age must be an integer")
    .positive("Age must be a positive number"),
  gender: Yup.string().required("Required"),
  city: Yup.string()

    .max(15, "Too Long!")
    .required("Required"),
  residentialState: Yup.string().required("Required"),

  bio: Yup.string()
    .max(1000, "Bio cannot exceed 1000 characters")
    .required("Required"),

  achievements: Yup.string().max(
    1000,
    "Achievements cannot exceed 1000 characters"
  ),
  grade: Yup.string().required("Required"),

  parentFirstName: Yup.string()

    .max(15, "Too Long!")
    .required("Required"),
  parentLastName: Yup.string()

    .max(15, "Too Long!")
    .required("Required"),

  parentPhone: Yup.string().required("Required"),
  parentConsent: Yup.boolean().required("Required"),

  sportsId: Yup.string().required("Required"),
  subscriptionId: Yup.string().required("Required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  profileImg: "",
  age: "",
  gender: "",
  city: "",
  residentialState: "",
  school: "",
  grade: "",
  bio: "",
  achievements: "",
  parentFirstName: "",
  parentLastName: "",
  parentEmail: "",
  parentPhone: "",
  parentConsent: "",
  twitterLink: "",
  instagramLink: "",
  sportsId: null,
  subscriptionId: "",
  athleteSpecialty: "",
  currentAcademie: "",
  subscriptionStatus: "",
  isApprove: "",
  isActive: "",
};
const EditAthleteOffcanvas = forwardRef((props, ref) => {
  const [editAthlete, setEditAthlete] = useState(false);
  const [sportData, setSportData] = useState(null);
  const [formData, setFormData] = useState(initialValues);
  const [galleryId, setGalleryId] = useState(null);
  const [profile, setProfile] = useState(null);
  const [athleteId, setAthleteId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSportIDs, setSelectedSportIDs] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [headshotImages, setHeadshotImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [videosData, setVideosData] = useState({
    videoTitle: "",
    videoUrl: "",
  });
  const [videosDataList, setVideosDataList] = useState([]);
  const [editVideoId, setEditVideoId] = useState(null);
  const [showMediaPopup, setShowMediaPopup] = useState(false);
  const [media, setMedia] = useState({});
  const [referalCodeList, setReferalCodeList] = useState([]);

  const fetchReferralsForAthlete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_PAYMENT_API_URI}/getAllAthleteAssignedRef`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token}`,
            Accept: "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        // setReferalCodeList(response.data.unusedCodes);
      }
    } catch (error) {
      console.error("Something went wrong");
    }
  };

  const handleVideoDataChange = (event) => {
    const { name, value } = event.target;
    setVideosData({ ...videosData, [name]: value });
  };

  const fetchVideosData = async () => {
    const userId = sessionStorage.getItem("id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      toast.error("Authentication credentials not found.");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_USERPROFILE_API_URL}/getUserVideos/${userId}`,
        {
          headers: {
            Authorization: `${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 || 201) {
        setVideosDataList(response.data.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error || "something went wrong");
    }
  };

  const handleAddVideoData = async () => {
    const userId = sessionStorage.getItem("id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      toast.error("Authentication credentials not found.");
      return;
    }
    if (
      videosDataList?.length <= 3 &&
      videosData.videoTitle &&
      videosData.videoUrl
    ) {
      const formData = {
        videoTitle: videosData.videoTitle,
        videoLink: videosData.videoUrl,
        userId: userId,
      };
      try {
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_USERPROFILE_API_URL}/addvideo`,
          formData,
          {
            headers: {
              Authorization: `${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200 || 201) {
          toast.success("Video data added successfully");
          fetchVideosData();
          setVideosData({ videoTitle: "", videoUrl: "" });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error(error || "something went wrong");
      }
    }
  };

  const handleEditVideoData = (id) => {
    const selectedData = videosDataList?.find((each) => each.id === id);
    setVideosData({
      videoTitle: selectedData?.videoTitle,
      videoUrl: selectedData?.videoLink,
    });
    setEditVideoId(id);
  };

  const handleUpdateVideoData = async () => {
    const userId = sessionStorage.getItem("id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      toast.error("Authentication credentials not found.");
      return;
    }

    if (videosData.videoTitle && videosData.videoUrl) {
      const formData = {
        videoTitle: videosData.videoTitle,
        videoLink: videosData.videoUrl,
        userId: userId,
      };
      try {
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_USERPROFILE_API_URL}/updatevideo/${editVideoId}`,
          formData,
          {
            headers: {
              Authorization: `${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200 || 201) {
          toast.success("Video data updated successfully");
          fetchVideosData();
          setVideosData({ videoTitle: "", videoUrl: "" });
          setEditVideoId(null);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error(error || "something went wrong");
      }
    }
  };

  const handleDeleteVideoData = async (id) => {
    const userId = sessionStorage.getItem("id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      toast.error("Authentication credentials not found.");
      return;
    }
    try {
      const response = await axios.delete(
        `${process.env.NEXT_PUBLIC_USERPROFILE_API_URL}/deletevideo/${id}`,
        {
          headers: {
            Authorization: `${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Video data deleted successfully");
        fetchVideosData();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error || "something went wrong");
    }
  };

  useImperativeHandle(ref, () => ({
    showEmployeModal(id) {
      console.log("Athlete ID:", id);
      setEditAthlete(true);
      fetchAthleteById(id);
      fetchAge();
      fetchStates();
      fetchVideosData();
      fetchReferralsForAthlete();
    },
  }));

  const handleVideoUpload = async (event) => {
    const files = Array.from(event.currentTarget.files).slice(0, 3);
    const newValidVideos = [];
    const newVideoPreviews = [];
    const errors = [];

    for (const file of files) {
      const isValid = await validateVideo(file);
      if (isValid) {
        newValidVideos.push(file);
        newVideoPreviews.push(URL.createObjectURL(file));
      } else {
        toast.error(`${file.name} is either too long or too large.`);
      }
    }

    setSelectedVideos((prevVideos) => [...prevVideos, ...newValidVideos]);
    setVideoPreviews((prevPreviews) => [...prevPreviews, ...newVideoPreviews]);
    setValidationErrors((prevErrors) => [...prevErrors, ...errors]);
  };

  const validateVideo = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        const fileSize = file.size / 1024; // Convert to KB

        if (duration <= 30 && fileSize <= 250) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      video.src = URL.createObjectURL(file);
    });
  };

  useEffect(() => {
    setCities([]);
    if (selectedStateId) {
      fetchCitiesByState(selectedStateId);
    }
  }, [selectedStateId]);

  useEffect(() => {
    if (formData.sportsId) {
      fetchSpecialtiesBysportsId(formData.sportsId);
    }
  }, [formData.sportsId]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_SPORTS_API_URI}/getallsports`, {
        headers: {
          authorization: `${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSportData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sportData data:", error);
      });
  }, []);
  const fetchAge = async () => {
    try {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("id");
      console.log(id);
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getsettings/1`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );

      const fromAge = parseInt(response.data.data.fromAge);
      const toAge = parseInt(response.data.data.toAge);

      const options = [];
      for (let i = fromAge; i <= toAge; i++) {
        options.push(i.toString());
      }
      setAgeOptions(options);
    } catch (error) {
      console.error("Error fetching age:", error);
    }
  };

  const handleApprove = async (videoId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/galleryisapprove/${videoId}`,
        { isApproved: true },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      toast.success("Video approved successfully!");
      fetchAthleteById(athleteId);
    } catch (error) {
      console.error("Error approving video:", error);
      toast.error("Failed to approve video.");
    }
  };

  const handleReject = async (videoId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/delete-file/${videoId}`,
        {},
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      toast.success("Video rejected successfully!");
      fetchAthleteById(athleteId);
    } catch (error) {
      console.error("Error rejecting video:", error);
      toast.error("Failed to reject video.");
    }
  };
  const [selectedFiles, setSelectedFiles] = useState({});

  const handleFileSelection = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const fileSize = file.size / 1024;
      if (fileSize > 250) {
        toast.error("File size exceeds 250 KB");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const { width, height } = img;
          // if (width >= 290 && width <= 300 && height >= 290 && height <= 300) {
          const fileURL = URL.createObjectURL(file);
          const updatedFiles = {
            ...selectedFiles,
            [index]: { file, fileURL },
          };
          setSelectedFiles(updatedFiles);
          // } else {
          //   toast.error(
          //     "Image dimensions must be between 290x290 and 300x300 pixels"
          //   );
          // }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAdditionalFileChange = async (index) => {
    const selectedFile = selectedFiles[index];
    if (selectedFile) {
      const { file, fileURL } = selectedFile;
      try {
        const token = localStorage.getItem("token");
        const fileFormData = new FormData();
        fileFormData.append("fileLocation", file);
        fileFormData.append("userId", athleteId);
        fileFormData.append("description", `Headshot Image`);
        fileFormData.append("isActive", "true");
        fileFormData.append("fileType", `Headshot Image`);
        fileFormData.append("isApproved", "true");

        await axios.post(
          `${process.env.REACT_APP_CONTENT_API_URI}/upload-singlefile`,
          fileFormData,
          {
            headers: {
              authorization: `${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("File uploaded successfully!");
        toast.success("File uploaded successfully!");

        const updatedImages = [...headshotImages];
        updatedImages[index] = { fileLocation: fileURL };
        setHeadshotImages(updatedImages);

        const updatedFiles = { ...selectedFiles };
        delete updatedFiles[index];
        setSelectedFiles(updatedFiles);
      } catch (error) {
        console.error("Error occurred during file upload:", error);
        toast.error("An error occurred during file upload.");
      }
    }
  };

  const handleSave = (index) => {
    handleAdditionalFileChange(index);
  };

  const handleDeleteAdditionalImage = async (index) => {
    try {
      const imageToDelete = headshotImages[index];

      if (!imageToDelete || !imageToDelete.id) {
        const updatedImages = [...headshotImages];
        updatedImages.splice(index, 1);
        setHeadshotImages(updatedImages);
        toast.success("Image deleted successfully!");
        return;
      }
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/delete-file/${imageToDelete.id}`,
        {},
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const updatedImages = [...headshotImages];
        updatedImages.splice(index, 1);
        setHeadshotImages(updatedImages);
        toast.success("Image deleted successfully!");
      } else {
        console.error("Failed to delete image:", response);
        toast.error("Failed to delete image.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
      } else {
        console.error("Error occurred during image deletion:", error);
        toast.error("An error occurred during image deletion.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const handleEditorChange = (editorName, data) => {
    setFormData((prevState) => ({
      ...prevState,
      [editorName]: data,
    }));
  };

  const handleStateChange = (selectedOption) => {
    setFormData({
      ...formData,
      residentialState: selectedOption.value,
      city: "",
    });
    fetchCitiesByState(selectedOption.value);
  };

  const handleCityChange = (selectedOption) => {
    setFormData({
      ...formData,
      city: selectedOption.value,
    });
  };

  useEffect(() => {
    if (formData?.residentialState) {
      fetchCitiesByState(formData.residentialState);
    }
  }, [formData?.residentialState]);

  const fetchCitiesByState = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallcities?stateId=${id}`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            authorization: `${token}`,
          },
        }
      );
      if (response.data && Array.isArray(response.data.citiesData)) {
        setCities(
          response.data.citiesData.map((city) => ({
            value: city.id,
            label: city.name,
          }))
        );
      } else {
        console.error("Error: citiesData is not an array or is undefined");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallstates`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            authorization: `${token}`,
          },
        }
      );
      setStateOptions(
        response.data.data.map((state) => ({
          value: state.id,
          label: state.name,
        }))
      ); // Map the states array for react-select
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchAthleteById = async (id) => {
    setProfileImage(null);
    setSelectedVideos([]);
    setHeadshotImages([]);

    try {
      const response = await new AthleteProfile().fetchAthleteDetails(id);
      setAthleteId(response.athleteData.id);

      setGalleryId(response.profilePictureId);
      setProfile(response.profilePictureUrl);

      setVideos(response.videosData);

      if (response.sortedProfileImages.length > 0) {
        setHeadshotImages(response.sortedProfileImages);
        setAdditionalImages(response.sortedProfileImages);
      }

      if (response.videosData.length > 0) {
        setVideo(response.videosData[0].fileLocation);
      }

      setSelectedSportIDs(response.athleteData.sportsId);
      setFormData(response.athleteData);
    } catch (error) {
      console.error("Error fetching athlete data:", error);
      toast.error("Failed to fetch athlete data!");
    }
  };

  useEffect(() => {
    if (selectedSportIDs) {
      //Optimize to get specific sport IDs only upon addition of new sport in the multi select
      fetchSpecialtiesBysportsId(selectedSportIDs);
    }
  }, [selectedSportIDs]);

  const fetchSpecialtiesBysportsId = async (sportsId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_SPORTS_API_URI}/getspecialitybysport?sportId=${sportsId}`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            authorization: `${token}`,
          },
        }
      );

      if (response.data && Array.isArray(response.data.data)) {
        setSpecialties(
          response.data.data.map((specialty) => ({
            value: specialty[0].id,
            label: specialty[0].specialityTitle,
          }))
        );
      } else {
        console.error("Error: specialties is not an array or is undefined");
      }
    } catch (error) {
      console.error("Error fetching specialties:", error);
    }
  };

  const handleSportChange = (e) => {
    const selectedsportsId = e.target.value;
    setFormData({
      ...formData,
      sportsId: selectedsportsId,
      athleteSpecialty: "", // Reset specialty when sport changes
    });
    fetchSpecialtiesBysportsId(selectedsportsId);
  };
  // Update athlete
  const handleSubmit = async (e, values) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      // Update athlete data
      if (galleryId) {
        values.galleryId = galleryId;
      }

      const data = {
        ...values,
        sportsId: values.sportsId.map((sport) => sport.value).join(","),
        athleteSpecialty: values.athleteSpecialty
          .map((specialty) => specialty.value)
          .join(","),
      };

      const response = await axios.post(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/updateathlete`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `${token}`,
          },
        }
      );

      if (response.data.status === 200 && response.data) {
        console.log("Response:", response.data);

        if (selectedFile && !galleryId) {
          console.log("Profile Uploading");
          const fileFormData = new FormData();
          fileFormData.append("userId", athleteId);
          fileFormData.append("fileLocation", selectedFile, selectedFile.name);
          fileFormData.append("description", "New Profile Image");
          fileFormData.append("isActive", "true");
          fileFormData.append("fileType", "Profile Image");
          fileFormData.append("isApproved", "true");
          console.log(fileFormData);

          await axios.post(
            `${process.env.REACT_APP_CONTENT_API_URI}/upload-singlefile`,
            fileFormData,
            {
              headers: {
                authorization: `${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("File uploaded successfully!");
          toast.success("File uploaded successfully!");
        }

        // Update existing profile picture
        if (selectedFile && galleryId) {
          const fileFormData = new FormData();
          fileFormData.append("id", galleryId);
          fileFormData.append("userId", response.data);
          fileFormData.append("fileLocation", selectedFile, selectedFile.name);
          fileFormData.append("description", "Profile Image");
          fileFormData.append("isActive", "true");
          fileFormData.append("fileType", "Profile Image");
          fileFormData.append("isApproved", "true");

          await axios.post(
            `${process.env.REACT_APP_CONTENT_API_URI}/update-file`,
            fileFormData,
            {
              headers: {
                authorization: `${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("File updated successfully!");
        }
        console.log("videoFile", values, selectedVideos);

        if (selectedVideos && selectedVideos.length > 0) {
          const videoFormData = new FormData();
          selectedVideos.forEach((file) => {
            videoFormData.append("fileLocation", file);
          });
          videoFormData.append("userId", athleteId);
          videoFormData.append("description", "Video Files");
          videoFormData.append("isActive", "true");
          videoFormData.append("fileType", "Video");
          videoFormData.append("isApproved", "true");

          try {
            await axios.post(
              `${process.env.REACT_APP_CONTENT_API_URI}/upload-multiplefiles`,
              videoFormData,
              {
                headers: {
                  authorization: `${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            console.log("Multiple video files uploaded successfully!");
          } catch (error) {
            if (error.response && error.response.status === 413) {
              console.error("File size too large.");
              toast.error("File Size Too Large");
            } else {
              console.error("An error occurred during file upload.");
              toast.error("An error occurred during file upload.");
            }
          }
        }

        toast.success("Athlete data updated successfully!");
        props.onAthleteUpdated();
        setEditAthlete(false);
      }
    } catch (error) {
      console.error("Error updating athlete data:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleShowPopup = (type, src) => {
    setMedia({ mediaType: type, mediaSrc: src });
    setShowMediaPopup(true);
  };

  const handleClosePopup = () => {
    setMedia({ mediaType: "", mediaSrc: "" });
    setShowMediaPopup(false);
  };

  return (
    <>
      <MediaPopup
        show={showMediaPopup}
        handleClose={handleClosePopup}
        type={media.mediaType}
        src={media.mediaSrc}
      />
      <Offcanvas
        show={editAthlete}
        onHide={() => setEditAthlete(false)}
        className="w-100"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h3 className="modal-title text-white" id="#gridSystemModal">
            {props.Title}
          </h3>

          <button
            type="button"
            className="btn-close"
            onClick={() => setEditAthlete(false)}
          >
            <i className="fa-solid fa-xmark color-white"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <Formik
              initialValues={formData || initialValues} // Use formData if available, otherwise use initialValues
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                props.handleSubmit(values);
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                values,
                errors,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent default form submission behavior
                    handleSubmit(e, values);
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-3 dz-default dlab-message upload-img">
                      {profile && (
                        <img
                          src={profile}
                          alt="Selected File"
                          className="cursor-pointer img-fluid mb-2"
                          onClick={() => handleShowPopup("image", profile)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column mb-3">
                      <label>Profile Picture</label>
                      <Field
                        type="file"
                        name="profileImg"
                        className="form-control"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          if (file) {
                            const fileSize = file.size / 1024; // size in KB
                            // if (fileSize > 200) {
                            //   toast.error("File size exceeds 200 KB");
                            //   return;
                            // }

                            const reader = new FileReader();
                            reader.onload = () => {
                              const img = new Image();
                              img.src = reader.result;
                              img.onload = () => {
                                const { width, height } = img;
                                // if (
                                //   width >= 290 &&
                                //   width <= 300 &&
                                //   height >= 290 &&
                                //   height <= 300
                                // ) {
                                setProfile(reader.result);
                                setSelectedFile(file);
                                // } else {
                                //   toast.error(
                                //     "Image dimensions must be between 290x290 and 300x300 pixels"
                                //   );
                                // }
                              };
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                      <p className="text-warning fs-12 text-wrap">
                        Height & Width should be between 290 - 300 pixels & only
                        GIF, JPG, JPEG, PNG, or SVG images up to 200 KB.{" "}
                      </p>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="referalCode" className="form-label">
                        Referral Code
                      </label>
                      <Select
                        id="referalCode"
                        name="referalCode"
                        options={referalCodeList}
                        onChange={(selectedOption) =>
                          setFieldValue("referalCode", selectedOption)
                        }
                        classNamePrefix="select"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="firstName" className="form-label">
                        Athlete First Name
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Athlete Last Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="age" className="form-label">
                        Age Group<span className="text-danger">*</span>
                      </label>
                      <Select
                        name="age"
                        options={[
                          { value: "1", label: "7-8" },
                          { value: "2", label: "9-10" },
                          { value: "3", label: "11-12" },
                          { value: "4", label: "13-14" },
                          { value: "5", label: "15-16" },
                          { value: "6", label: "17-18" },
                          { value: "7", label: "18+" },
                        ]}
                        classNamePrefix="react-select"
                        id="age"
                        onChange={(option) =>
                          setFieldValue("age", option.value)
                        }
                      />

                      <p className="text-danger">{errors.age}</p>
                    </div>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="gender" className="form-label">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        id="gender"
                        value={values.gender}
                        name="gender"
                      >
                        <option value="">Please select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Field>
                      <ErrorMessage
                        name="gender"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="school" className="form-label">
                        Current School
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="school"
                        name="school"
                      />
                      <ErrorMessage
                        name="school"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="grade" className="form-label">
                        Current Grade
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="grade"
                        name="grade"
                      />
                      <ErrorMessage
                        name="grade"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="residentialState" className="form-label">
                        Residential State <span className="text-danger">*</span>
                      </label>
                      <Select
                        id="residentialState"
                        name="residentialState"
                        options={stateOptions}
                        classNamePrefix="react-select"
                        value={
                          stateOptions.find(
                            (option) => option.value === values.residentialState
                          ) || null
                        }
                        onChange={handleStateChange}
                      />
                      <ErrorMessage
                        name="residentialState"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="city" className="form-label">
                        Residential City <span className="text-danger">*</span>
                      </label>
                      <Select
                        id="city"
                        name="city"
                        options={cities}
                        value={
                          cities.find(
                            (option) => option.value === values.city
                          ) || null
                        }
                        onChange={handleCityChange}
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-xl-12 mb-3">
                      {/* <label htmlFor="bio" className="form-label">
                        Bio
                      </label> */}
                      {/* <CKEditor
                        editor={ClassicEditor}
                        data={formData?.bio} // Use data prop instead of value
                        onChange={(event, editor) => {
                          setFieldTouched("bio", true);

                          handleEditorChange("bio", editor.getData()); // Call handleEditorChange with editorName
                        }}
                      /> */}
                      <TextEditor
                        content={formData?.bio}
                        setContent={(newContent) => {
                          setFieldTouched("bio", true);
                          handleEditorChange("bio", newContent);
                        }}
                        title={
                          "Tell us about your athlete’s interests, goals, and the coaching or resources you’re seeking to support their growth and success."
                        }
                      />
                      <ErrorMessage
                        name="bio"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <h3 className="profile-head">Parent Details</h3>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="parentFirstName" className="form-label">
                        Parent/Guardian First Name{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="parentFirstName"
                        name="parentFirstName"
                        placeholder="Parent/Guardian First Name"
                      />
                      <ErrorMessage
                        name="parentFirstName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="parentLastName" className="form-label">
                        Parent/Guardian Last Name{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="parentLastName"
                        name="parentLastName"
                        placeholder="Parent/Guardian Last Name"
                      />
                      <ErrorMessage
                        name="parentLastName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Parent/Guardian Email{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Parent/Guardian Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="parentPhone" className="form-label">
                        Parent/Guardian Contact Number{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="parentPhone"
                        name="parentPhone"
                        placeholder="Parent/Guardian Number"
                      />
                      <ErrorMessage
                        name="parentPhone"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3 ">
                      <label htmlFor="parentConsent" className="form-label p-2">
                        Parent/Guardian Consent{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="parentConsent"
                        name="parentConsent"
                        checked={formData?.parentConsent}
                        onChange={handleInputChange}
                      />
                      <div className="text-danger">
                        {formData?.parentConsent ? null : ""}
                      </div>
                    </div>

                    <h3 className="profile-head">Sport Details</h3>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="sportsId" className="form-label">
                        Primary Sport <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={
                          Array.isArray(sportData.data)
                            ? sportData.data.map((sport) => ({
                                value: sport.id,
                                label: sport.sportName,
                              }))
                            : []
                        }
                        placeholder="Select Sports"
                        onChange={(selectedOption) => {
                          // const selectedValues = selectedOption
                          //   ? [selectedOption].map((option) => option.value)
                          //   : [];

                          setFieldValue("athleteSpecialty", "");
                          setSelectedSportIDs(
                            [selectedOption]
                              .map((sportsId) => sportsId.value)
                              .join(",")
                          );
                          setFieldValue("sportsId", [selectedOption]);
                        }}
                        // isMulti
                        isClearable
                        value={values.sportsId} // Maintain the selected options in the dropdown
                      />
                      <ErrorMessage
                        name="sportsId"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="athleteSpecialty" className="form-label">
                        Speciality/Position
                      </label>
                      <Select
                        name="athleteSpecialty"
                        options={specialties}
                        classNamePrefix="react-select"
                        // isMulti
                        id="athleteSpecialty"
                        onChange={(option) => {
                          setFieldValue(
                            "athleteSpecialty",
                            // option ? [option].map((opt) => opt.value) : []
                            [option]
                          );
                        }}
                        value={values.athleteSpecialty}
                      />
                      <ErrorMessage
                        name="athleteSpecialty"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-12 mb-3">
                      {/* <label htmlFor="currentAcademie" className="form-label">
                        Current Academy
                      </label> */}
                      {/* <CKEditor
                        editor={ClassicEditor}
                        data={formData?.currentAcademie} // Use data prop instead of value
                        onChange={(event, editor) => {
                          setFieldTouched("currentAcademie", true);
                          handleEditorChange(
                            "currentAcademie",
                            editor?.getData()
                          ); // Call handleEditorChange with editorName
                        }}
                      /> */}
                      <TextEditor
                        content={formData?.currentAcademie}
                        setContent={(newContent) => {
                          setFieldTouched("currentAcademie", true);
                          handleEditorChange("currentAcademie", newContent);
                        }}
                        title={"Current Academy"}
                      />
                      <ErrorMessage
                        name="currentAcademie"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-xl-12 mb-3">
                      {/* <label htmlFor="achievements" className="form-label">
                        Achievements
                      </label> */}
                      {/* <CKEditor
                        editor={ClassicEditor}
                        data={formData?.achievements} // Use data prop instead of value
                        onChange={(event, editor) => {
                          setFieldTouched("achievements", true);
                          handleEditorChange("achievements", editor?.getData()); // Call handleEditorChange with editorName
                        }}
                      /> */}
                      <TextEditor
                        content={formData?.achievements}
                        setContent={(newContent) => {
                          setFieldTouched("achievements", true);
                          handleEditorChange("achievements", newContent);
                        }}
                        title={
                          "Share your athlete’s accomplishments in sports, academics, and beyond."
                        }
                      />
                      <ErrorMessage
                        name="achievements"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <h3 className="profile-head">Social Media</h3>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="twitterLink" className="form-label">
                        Twitter Link
                        {formData?.twitterLink && (
                          <a
                            href={`${formData?.twitterLink}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <SquareArrowOutUpRight
                              size={15}
                              className="cursor-pointer mx-2"
                            />
                          </a>
                        )}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="twitterLink"
                        name="twitterLink"
                        value={values.twitterLink || ""}
                      />
                      <ErrorMessage
                        name="twitterLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="instagramLink" className="form-label">
                        Instagram Profile Link
                        {formData?.instagramLink && (
                          <a
                            href={`${formData?.instagramLink}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <SquareArrowOutUpRight
                              size={15}
                              className="cursor-pointer mx-2"
                            />
                          </a>
                        )}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="instagramLink"
                        name="instagramLink"
                      />
                      <ErrorMessage
                        name="instagramLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <h3 className="profile-head">Gallery </h3>

                    <div className="pl-25">
                      <label htmlFor="additionalImage" className="form-label">
                        Gallery Image
                      </label>
                      <p className="text-danger">
                        Image dimensions must be between 290x290 and 300x300
                        pixels
                      </p>
                      <p className="text-danger">File Size under 250KB</p>
                      <div className="row mt-3">
                        {headshotImages &&
                          headshotImages.length > 0 &&
                          headshotImages.map((image, index) => (
                            <div
                              key={index}
                              className="col-xl-4 mb-3 p-2 d-grid"
                            >
                              <img
                                src={image.fileLocation}
                                alt={`Headshot Image ${index + 1}`}
                                style={{ width: "200px", height: "225px" }}
                              />
                              <button
                                type="button"
                                className="btn btn-danger btn-md mt-2"
                                onClick={() =>
                                  handleDeleteAdditionalImage(index)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          ))}
                        {[...Array(Math.max(0, 3 - headshotImages.length))].map(
                          (_, index) => (
                            <div
                              key={index}
                              className="col-xl-4 mb-3 p-2 d-grid"
                            >
                              <div className="d-flex align-items-center">
                                <input
                                  type="file"
                                  className="file-upload-input visually-hidden"
                                  id={`additionalImage${
                                    headshotImages.length + index
                                  }`}
                                  name={`additionalImage${
                                    headshotImages.length + index
                                  }`}
                                  onChange={(e) =>
                                    handleFileSelection(
                                      e,
                                      headshotImages.length + index
                                    )
                                  }
                                />
                                <img
                                  src={
                                    selectedFiles[headshotImages.length + index]
                                      ?.fileURL || noimage
                                  }
                                  alt="No Image Available"
                                  style={{ width: "200px", height: "225px" }}
                                />
                                <label
                                  htmlFor={`additionalImage${
                                    headshotImages.length + index
                                  }`}
                                  className="btn btn-primary btn-sm mt-2"
                                  style={{ height: "max-content" }}
                                >
                                  +
                                </label>
                              </div>
                              <button
                                width="80"
                                type="button"
                                className="btn btn-primary btn-md mt-2"
                                onClick={() =>
                                  handleSave(headshotImages.length + index)
                                }
                              >
                                Save
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {/* <div className="col-xl-12 mb-3 pl-25">
                      <label htmlFor="videoFile" className="form-label">
                        Video
                      </label>

                      <div className="file-upload-wrapper pl-25">
                        <input
                          type="file"
                          className="file-upload-input"
                          id="videoFile"
                          name="videoFile"
                          multiple
                          accept="video/*" // Accept only video files
                          onChange={handleVideoUpload}
                        />
                        <label
                          htmlFor="videoFile"
                          className="file-upload-label"
                        >
                          +
                        </label>
                      </div>
                      <p className="text-danger">
                        Video Duration should be maximum 30 seconds
                      </p>
                      <p className="text-danger">Video Size under 250KB</p>
                      {validationErrors.length > 0 && (
                        <div className="validation-errors">
                          {validationErrors.map((error, index) => (
                            <p key={index} className="text-danger">
                              {error}
                            </p>
                          ))}
                        </div>
                      )}
                      <div className="col-xl-12 mb-3 p-2 d-flex">
                        {videoPreviews.length > 0 &&
                          videoPreviews.map((video, index) => (
                            <div key={index} className="video-container p-2">
                              <video controls width="300" height="300">
                                <source src={video} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          ))}
                        {videos.length > 0 &&
                          videos.map((video) => (
                            <div key={video.id} className="video-container p-2">
                              <video controls width="300" height="300">
                                <source
                                  src={video.fileLocation}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                              {!video.isApproved && (
                                <div className="video-actions d-flex">
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-md mt-2 me-1"
                                      onClick={() => handleApprove(video.id)}
                                    >
                                      Approve
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-md mt-2"
                                      onClick={() => handleReject(video.id)}
                                    >
                                      Reject
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-12 col-md-5">
                        <div className="form-group">
                          <label htmlFor="videoTitle">Video Title</label>
                          <Field
                            name="videoTitle"
                            type="text"
                            className="form-control"
                            placeholder="Enter Video Title"
                            value={videosData.videoTitle}
                            onChange={handleVideoDataChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-5">
                        <div className="form-group">
                          <label htmlFor="videoUrl">Video URL</label>
                          <Field
                            name="videoUrl"
                            type="text"
                            className="form-control"
                            placeholder="Enter Video URL"
                            value={videosData?.videoUrl}
                            onChange={handleVideoDataChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-2 mb-3 d-flex justify-content-center align-items-end">
                        {Boolean(editVideoId) ? (
                          <Button
                            variant="outline"
                            className="py-2"
                            onClick={handleUpdateVideoData}
                          >
                            Update
                          </Button>
                        ) : (
                          videosDataList?.length < 3 && (
                            <Button
                              className="py-2"
                              onClick={handleAddVideoData}
                            >
                              Add
                            </Button>
                          )
                        )}
                      </div>
                    </div>

                    <div className="col-12 mb-3 d-flex flex-column">
                      {videosDataList?.length > 0 && (
                        <>
                          <h5>Videos Details</h5>
                          <ul className="d-flex flex-column">
                            {videosDataList?.map((each, index) => (
                              <li
                                className="row d-flex gap-2 align-items-center p-0 justify-content-between flex-wrap"
                                key={each.id}
                              >
                                <div className="col-12 col-sm-8 mb-3">
                                  <h6 className="p-0 m-0">{each.videoTitle}</h6>
                                  <a href={each.videoLink} target="_blank">
                                    <span className="text-wrap break-word">
                                      {each.videoLink}
                                    </span>
                                  </a>
                                </div>
                                <div className="col-12 col-sm-3 d-flex align-items-center gap-4 flex-wrap">
                                  <Pencil
                                    size={20}
                                    className="cursor-pointer"
                                    onClick={() => handleEditVideoData(each.id)}
                                  />
                                  <Trash2
                                    size={20}
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleDeleteVideoData(each.id)
                                    }
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary me-1"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                    <Link
                      to="#"
                      onClick={() => setEditAthlete(false)}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Offcanvas>
      <ToastContainer />
    </>
  );
});

export default EditAthleteOffcanvas;
