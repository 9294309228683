import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Info, Pencil, SquareArrowOutUpRight, Trash2 } from "lucide-react";
import moment from "moment";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Button,
  Offcanvas,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import SportsManagerService from "../../api/sportsManager/sportsManager.service";
import noimage from "../../images/noprofile/noprofile2.jpg";
import MediaPopup from "../components/Common/MediaPopup";
import TextEditor from "../components/Common/TextEditor";
const validationSchema = Yup.object().shape({
  firstName: Yup.string()

    // .max(15, "Too Long!")
    .required("Required"),
  lastName: Yup.string()

    // .max(15, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),

  profileImg: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  bio: Yup.string()
    .required("Required")
    .max(1000, "Bio must be at most 1000 characters"),
  achievements: Yup.string()
    .required("Required")
    .max(500, "Achievements must be at most 500 characters"),
  twitterLink: Yup.string().url("Invalid URL").required("Required"),
  instagramLink: Yup.string().url("Invalid URL").required("Required"),
  websiteLink: Yup.string().url("Invalid URL").required("Required"),

  // ageYouCoach: Yup.array()
  //   .of(Yup.number().positive("Age must be a positive number"))
  //   .required("Required"),

  sportsId: Yup.string().required("Required"),
  coachSpecialty: Yup.string().required("Required"),
  genderYouCoach: Yup.array()
    .min(1, "At least one gender you coach is required")
    .required("Required"),
  currentAcademie: Yup.string().required("Required"),
});
const EditCoachOffcanvas = forwardRef((props, ref) => {
  const [editCoach, setEditCoach] = useState(false);
  const [ageOptions, setAgeOptions] = useState([]);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [editCoach1, setEditCoach1] = useState(false);
  const [coachData, setCoachData] = useState(null);
  const [sportData, setSportData] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [selectedSportIDs, setSelectedSportIDs] = useState([]);
  const [formData, setFormData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [galleryId, setGalleryId] = useState(null);
  const [headshotImages, setHeadshotImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [profile, setProfile] = useState(null);
  const [coachId, setCoachId] = useState(null);
  const [age, setAge] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState([]);
  const [selectedCityIds, setSelectedCityIds] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [media, setMedia] = useState({});
  const [showMediaPopup, setShowMediaPopup] = useState(false);
  const [ageGroupError, setAgeGroupError] = useState("");
  const [termsAcceptedDate, setTermsAcceptedDate] = useState(null);
  const [videosData, setVideosData] = useState({
    videoTitle: "",
    videoUrl: "",
  });
  const [videosDataList, setVideosDataList] = useState([]);
  const [editVideoId, setEditVideoId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [referalCodeList, setReferalCodeList] = useState([]);

  useEffect(() => {
    fetchVideosData();
  }, []);

  useEffect(() => {
    setProfile(null);
    setSelectedFile(null);
  }, [editCoach1]);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    age: "",
    gender: "",
    city: [],
    state: "",
    school: "",
    bio: "",
    sportsId: "",
    coachSpecialty: "",
    twitterLink: "",
    instagramLink: "",
    websiteLink: "",
    ageYouCoach: formData ? formData.ageYouCoach : [],
    genderYouCoach: formData ? formData.genderYouCoach : [],
    isApprove: "", // Add approval status
    isActive: "",
  };

  // const handleAgeChange = (selectedOptions) => {
  //   setFieldValue("ageYouCoach", selectedOptions);
  // };

  const fetchReferralsForCoach = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_PAYMENT_API_URI}/getunusedref`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token}`,
            Accept: "application/json",
          },
        }
      );
      console.log("refferal", response);
      if (response.status === 200) {
        setReferalCodeList(response.data.unusedCodes);
      }
    } catch (error) {
      console.error("Something went wrong");
    }
  };

  const handleVideoUpload = async (event) => {
    const files = Array.from(event.currentTarget.files).slice(0, 3);
    const newValidVideos = [];
    const newVideoPreviews = [];
    const errors = [];

    for (const file of files) {
      const isValid = await validateVideo(file);
      if (isValid) {
        newValidVideos.push(file);
        newVideoPreviews.push(URL.createObjectURL(file));
      } else {
        toast.error(`${file.name} is either too long or too large.`);
      }
    }

    setSelectedVideos((prevVideos) => [...prevVideos, ...newValidVideos]);
    setVideoPreviews((prevPreviews) => [...prevPreviews, ...newVideoPreviews]);
    setValidationErrors((prevErrors) => [...prevErrors, ...errors]);
  };

  const validateVideo = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        const fileSize = file.size / 1024; // Convert to KB

        if (duration <= 30 && fileSize <= 250) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      video.src = URL.createObjectURL(file);
    });
  };

  useImperativeHandle(ref, () => ({
    showEmployeModal(id) {
      console.log("coach id", id);
      setEditCoach1(true);
      fetchCoachById(id);
      fetchVideosData(id);
      fetchReferralsForCoach();
    },
    showClubModal(id) {
      console.log("coach ID:", id);
      setEditCoach1(true);
      fetchCoachById(id);
      fetchVideosData(id);
      fetchReferralsForCoach();
    },
  }));

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_SPORTS_API_URI}/getallsports`, {
        headers: {
          authorization: `${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSportData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sportData data:", error);
      });
  }, []);

  useEffect(() => {
    fetchAge();
    fetchStates();
  }, []);

  const fetchAge = async () => {
    try {
      const options = [
        { value: "1", label: "7-8" },
        { value: "2", label: "9-10" },
        { value: "3", label: "11-12" },
        { value: "4", label: "13-14" },
        { value: "5", label: "15-16" },
        { value: "6", label: "17-18" },
        { value: "7", label: "18+" },
      ];
      setAgeOptions(options);
    } catch (error) {
      console.error("Error setting age options:", error);
    }
  };
  useEffect(() => {
    if (coachData && coachData.coachData) {
      const cities = coachData.citiesData.map((city) => ({
        value: city.id,
        label: city.name,
      }));

      const stateIds = coachData.coachData.state.split(",");
      const states = coachData.stateData
        .filter((state) => stateIds.includes(String(state.id)))
        .map((state) => ({
          value: state.id,
          label: state.name,
        }));

      setSpecialityData([]);

      const coachSpecialtyIds = coachData.coachData.coachSpecialty.split(",");
      const coachSpecialtyOptions = coachData.specialityData
        .filter((specialty) => coachSpecialtyIds.includes(String(specialty.id)))
        .map((specialty) => ({
          value: specialty.id,
          label: specialty.specialityTitle,
        }));
      console.log(coachData.coachData.ageYouCoach, "age");
      setFormData((prevData) => ({
        ...prevData,
        id: coachData.userData.id,
        firstName: coachData.userData.firstName,
        lastName: coachData.userData.lastName,
        email: coachData.userData.email,
        gender: coachData.coachData.gender,
        phone: coachData.coachData.phone,
        city: cities,
        state: states,
        bio: coachData.coachData.bio,
        achievements: coachData.coachData.achievements,
        twitterLink: coachData.coachData.twitterLink,
        instagramLink: coachData.coachData.instagramLink,
        websiteLink: coachData.coachData.websiteLink,
        ageYouCoach: coachData.coachData.ageYouCoach.split(",").map((age) => ({
          value: age,
          label: ageOptions?.find((each) => each.value === age).label,
        })),
        genderYouCoach: coachData.coachData.genderYouCoach
          .split(",")
          .map((gender) => ({ value: gender, label: gender })),
        coachSpecialty: coachSpecialtyOptions,

        sportsId: coachData.coachData.sportsId,
        isApprove: coachData.coachData.isApprove,
        isActive: coachData.coachData.isActive,
        galleryId: coachData.coachData?.galleryId,
      }));
      setCoachId(coachData.userData.id);
      setSelectedSportIDs(coachData.coachData.sportsId);
      const profileImg =
        coachData?.galleryData?.length > 0 &&
        coachData?.galleryData?.find(
          (each) => each.fileType === "Profile Image"
        );
      Boolean(profileImg) && setProfile(profileImg?.fileLocation);
      setTermsAcceptedDate(coachData.userData.lastTermsAcceptedAt);
    }
  }, [coachData]);

  console.log(formData);

  const handleDeleteAdditionalImage = async (index) => {
    try {
      const imageToDelete = headshotImages[index];

      if (!imageToDelete || !imageToDelete.id) {
        const updatedImages = [...headshotImages];
        updatedImages.splice(index, 1);
        setHeadshotImages(updatedImages);
        toast.success("Image deleted successfully!");
        return;
      }
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/delete-file/${imageToDelete.id}`,
        {},
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const updatedImages = [...headshotImages];
        updatedImages.splice(index, 1);
        setHeadshotImages(updatedImages);
        toast.success("Image deleted successfully!");
      } else {
        console.error("Failed to delete image:", response);
        toast.error("Failed to delete image.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
      } else {
        console.error("Error occurred during image deletion:", error);
        toast.error("An error occurred during image deletion.");
      }
    }
  };

  const handleSave = (index) => {
    handleAdditionalFileChange(index);
  };

  const handleAdditionalFileChange = async (index) => {
    const selectedFile = selectedFiles[index];
    if (selectedFile) {
      const { file, fileURL } = selectedFile;
      try {
        const token = localStorage.getItem("token");
        const fileFormData = new FormData();
        fileFormData.append("fileLocation", file);
        fileFormData.append("userId", coachId);
        fileFormData.append("description", `Headshot Image`);
        fileFormData.append("isActive", "true");
        fileFormData.append("fileType", `Headshot Image`);
        fileFormData.append("isApproved", "true");

        await axios.post(
          `${process.env.REACT_APP_CONTENT_API_URI}/upload-singlefile`,
          fileFormData,
          {
            headers: {
              authorization: `${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("File uploaded successfully!");
        toast.success("File uploaded successfully!");

        const updatedImages = [...headshotImages];
        updatedImages[index] = { fileLocation: fileURL };
        setHeadshotImages(updatedImages);

        const updatedFiles = { ...selectedFiles };
        delete updatedFiles[index];
        setSelectedFiles(updatedFiles);
      } catch (error) {
        console.error("Error occurred during file upload:", error);
        toast.error("An error occurred during file upload.");
      }
    }
  };

  const handleApprove = async (videoId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/galleryisapprove/${videoId}`,
        { isApproved: true },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      toast.success("Video approved successfully!");
      fetchCoachById(coachId);
    } catch (error) {
      console.error("Error approving video:", error);
      toast.error("Failed to approve video.");
    }
  };

  const handleReject = async (videoId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/delete-file/${videoId}`,
        {},
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      toast.success("Video rejected successfully!");
      fetchCoachById(coachId);
    } catch (error) {
      console.error("Error rejecting video:", error);
      toast.error("Failed to reject video.");
    }
  };

  const handleFileSelection = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const fileSize = file.size / 1024;
      // if (fileSize > 250) {
      //   toast.error("File size exceeds 250 KB");
      //   return;
      // }

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const { width, height } = img;
          // if (width >= 290 && width <= 300 && height >= 290 && height <= 300) {
          const fileURL = URL.createObjectURL(file);
          const updatedFiles = {
            ...selectedFiles,
            [index]: { file, fileURL },
          };
          setSelectedFiles(updatedFiles);
          // } else {
          //   toast.error(
          //     "Image dimensions must be between 290x290 and 300x300 pixels"
          //   );
          // }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // Inside fetchCoachById function
  const fetchCoachById = async (id) => {
    try {
      setHeadshotImages([]);

      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getcoach/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${token}`,
            Accept: "application/json",
          },
        }
      );

      const responseData = response.data.data;
      console.log("Fetched coach data:", responseData);

      // Set coach data
      setCoachData(responseData);

      // Set selected state IDs
      const selectedStateIds = responseData?.coachData?.state
        .split(",")
        .map(Number);
      setSelectedStateId(selectedStateIds);

      // Fetch and set state data

      console.log(
        "===============******************************************====responseData==========",
        responseData
      );
      const stateData = responseData.stateData.map((state) => ({
        value: state.id,
        label: state.name,
      }));
      // setState(stateData);

      for (const stateId of selectedStateIds) {
        const citiesResponse = await axios.get(
          `${process.env.REACT_APP_STATE_TAX_API_URI}/getallcities?stateId=${stateId}`,
          {
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "multipart/form-data",
              authorization: `${token}`,
            },
          }
        );
        console.log(citiesResponse);
        const citiesData = citiesResponse.data.citiesData?.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setCities((prevCities) => [...prevCities, ...citiesData]);

        const galleryData = responseData.galleryData;
        const headshot = galleryData.filter((item) =>
          item.fileType.startsWith("Headshot Image")
        );
        const sortedHeadshot = headshot.sort((a, b) => {
          const getFileNumber = (fileType) =>
            parseInt(fileType.replace("Headshot Image ", ""), 10);
          return getFileNumber(a.fileType) - getFileNumber(b.fileType);
        });

        setHeadshotImages(sortedHeadshot);
      }
      console.log("city", cities);
    } catch (error) {
      console.error("Error fetching coach data:", error);
    }
  };

  useEffect(() => {
    if (selectedSportIDs) {
      //Optimize to get specific sport IDs only upon addition of new sport in the multi select
      fetchCoachSpecialties(selectedSportIDs);
    }
  }, [selectedSportIDs]);

  const fetchCoachSpecialties = async (sportsId) => {
    try {
      const response = await SportsManagerService.getSpecialitiesBySport(
        sportsId
      );

      console.log("Response from fetchAthleteSpecialties:", response);

      if (response.status === 404) {
        setSpecialityData([]);
        console.log("No athlete specialties found for the selected sport.");
        // setShowSelectInput(true);
      } else if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        const specialties = response.data.data.map((spec) => ({
          id: spec[0].id,
          specialityTitle: spec[0].specialityTitle,
        }));
        setSpecialityData(specialties);
        console.log("Athlete specialties:", specialties);
        // setShowSelectInput(false);
      } else {
        console.error(
          "Error: Unable to find athlete specialties in the response data"
        );
      }
    } catch (error) {
      console.error("Error fetching athlete specialties:", error);
    }
  };

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallstates`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      const stateData = response.data.data.map((state) => ({
        value: state.id,
        label: state.name,
      }));

      console.log(
        "===========================stateData==========================",
        stateData
      );
      setState(stateData);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    if (selectedStateId.length > 0) {
      fetchCitiesByState(selectedStateId);
    } else {
      setCities([]);
    }
  }, [selectedStateId]);

  const fetchCitiesByState = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_STATE_TAX_API_URI}/getallcities?stateId=${id}`,
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
          },
        }
      );
      console.log("city", response.data.citiesData);
      if (response.data.citiesData && response.data.citiesData.length > 0) {
        // Flatten the citiesData array directly
        const citiesData = response.data.citiesData.flat().map((city) => ({
          value: city.id,
          label: city.name,
        }));

        setCities(citiesData);
      } else {
        console.error(
          "Error fetching cities: citiesData is undefined or empty"
        );
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  console.log(coachData);

  const handleSubmit = async (e, values) => {
    e.preventDefault();
    const stateIds = values?.state?.value
      ? values.state.value
      : values.state[0].value;
    // values.state.map((state) => state.value).join(",");
    const cityIds = values.city.map((city) => city.value).join(",");
    const ageYouCoach = values?.ageYouCoach.map((item) => item.value).join(",");
    const coachSpecialty = values.coachSpecialty?.value || "1";
    // values.coachSpecialty.map((item) => item.value).join(",") || "1"; //Default select General Speciality
    const genderYouCoach = values.genderYouCoach
      ?.map((item) => item.value)
      .join(",");
    // .map((item) => item.value)
    // .join(",");
    console.log("Submitting form with values:", values);

    //Coach can select Only 1 state and can select multiple cities
    const coachLocations = cityIds.split(",").map((cityId) => ({
      cityId: cityId,
      stateId: stateIds,
    }));

    //Coach can select only 1 Sport and that sport can have multiple specialities
    const coachSportSpeciality = coachSpecialty
      .toString()
      .split(",")
      .map((specialityId) => ({
        specialityId: specialityId,
        sportId: values.sportsId,
      }));
    const coachSportSpecaility = coachSpecialty
      .toString()
      .split(",")
      .map((specialityId) => ({
        specialityId: specialityId,
        sportId: values.sportsId,
      }));

    const coachAgeGroup = ageYouCoach.split(",").map((opt) => opt);

    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const formattedValues = {
        ...values,
        coachSpecialty: coachSpecialty,
        state: stateIds,
        city: cityIds,
        ageYouCoach,
        genderYouCoach,
        profileImg: profile || "",
        coachLocations,
        coachSportSpeciality,
        coachAgeGroup,
      };

      if (galleryId) {
        formattedValues.galleryId = galleryId;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/updatecoach`,
        formattedValues,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `${token}`,
          },
        }
      );
      console.log("Response:", response.data);

      if (response.data.status === 200 && response.data) {
        console.log("Response:", response.data);

        if (selectedFile && !galleryId) {
          console.log("Profile Uploading");
          const fileFormData = new FormData();
          fileFormData.append("userId", coachId);
          fileFormData.append("fileLocation", selectedFile, selectedFile.name);
          fileFormData.append("description", "New Profile Image");
          fileFormData.append("isActive", "true");
          fileFormData.append("fileType", "Profile Image");
          fileFormData.append("isApproved", "true");
          console.log(fileFormData);

          await axios.post(
            `${process.env.REACT_APP_CONTENT_API_URI}/upload-singlefile`,
            fileFormData,
            {
              headers: {
                authorization: `${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("File uploaded successfully!");
          toast.success("File uploaded successfully!");
          setLoading(false);
        }

        // Update existing profile picture
        if (selectedFile && galleryId) {
          const fileFormData = new FormData();
          fileFormData.append("id", galleryId);
          fileFormData.append("userId", response.data);
          fileFormData.append("fileLocation", selectedFile, selectedFile.name);
          fileFormData.append("description", "Updated Profile Image");
          fileFormData.append("isActive", "true");
          fileFormData.append("fileType", "Profile Image");
          fileFormData.append("isApproved", "true");

          await axios.post(
            `${process.env.REACT_APP_CONTENT_API_URI}/update-file`,
            fileFormData,
            {
              headers: {
                authorization: `${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("File updated successfully!");
          toast.success("File updated successfully!");
        }
        toast.success("Coach data updated successfully!");
        props.onCoachUpdated();
        setEditCoach1(false);
      }
    } catch (error) {
      console.error("Error updating coach data:", error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleVideoDataChange = (event) => {
    const { name, value } = event.target;
    setVideosData({ ...videosData, [name]: value });
  };

  const fetchVideosData = async () => {
    const userId = localStorage.getItem("id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      toast.error("Authentication credentials not found.");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getUserVideos/${coachId}`,
        {
          headers: {
            Authorization: `${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 || 201) {
        setVideosDataList(response.data.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error || "something went wrong");
    }
  };

  const handleAddVideoData = async () => {
    const userId = localStorage.getItem("id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      toast.error("Authentication credentials not found.");
      return;
    }
    if (
      videosDataList?.length <= 3 &&
      videosData.videoTitle &&
      videosData.videoUrl
    ) {
      const formData = {
        videoTitle: videosData.videoTitle,
        videoLink: videosData.videoUrl,
        userId: userId,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_USER_PROFILE_API_URI}/addvideo`,
          formData,
          {
            headers: {
              Authorization: `${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200 || 201) {
          toast.success("Video data added successfully");
          fetchVideosData();
          setVideosData({ videoTitle: "", videoUrl: "" });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error(error || "something went wrong");
      }
    }
  };

  const handleEditVideoData = (id) => {
    const selectedData = videosDataList?.find((each) => each.id === id);
    setVideosData({
      videoTitle: selectedData?.videoTitle,
      videoUrl: selectedData?.videoLink,
    });
    setEditVideoId(id);
  };

  const handleUpdateVideoData = async () => {
    const userId = localStorage.getItem("id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      toast.error("Authentication credentials not found.");
      return;
    }

    if (videosData.videoTitle && videosData.videoUrl) {
      const formData = {
        videoTitle: videosData.videoTitle,
        videoLink: videosData.videoUrl,
        userId: userId,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_USER_PROFILE_API_URI}/updatevideo/${editVideoId}`,
          formData,
          {
            headers: {
              Authorization: `${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200 || 201) {
          toast.success("Video data updated successfully");
          fetchVideosData();
          setVideosData({ videoTitle: "", videoUrl: "" });
          setEditVideoId(null);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error(error || "something went wrong");
      }
    }
  };

  const handleDeleteVideoData = async (id) => {
    const userId = localStorage.getItem("id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      toast.error("Authentication credentials not found.");
      return;
    }
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/deletevideo/${id}`,
        {
          headers: {
            Authorization: `${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Video data deleted successfully");
        fetchVideosData();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error || "something went wrong");
    }
  };

  const handleStateChange = (selectedOption) => {
    if (selectedOption) {
      const selectedId = selectedOption.value;
      setSelectedStateId(selectedId); // Save the selected state ID
      fetchCitiesByState(selectedId);
      setFormData((prevData) => ({ ...prevData, state: selectedOption })); // Update form data with the selected option
      console.log("Selected State ID:", selectedId);
    } else {
      console.error("Selected option is not valid:", selectedOption);
    }
  };

  const handleCityChange = (selectedOptions) => {
    if (Array.isArray(selectedOptions)) {
      const selectedIds = selectedOptions.map((option) => option.value);
      setSelectedCityIds(selectedIds);
      setFormData((prevData) => ({ ...prevData, city: selectedOptions }));
      console.log("Selected City IDs:", selectedIds);
    } else {
      console.error("Selected options is not an array:", selectedOptions);
    }
  };

  const handleShowPopup = (type, src) => {
    setMedia({ mediaType: type, mediaSrc: src });
    setShowMediaPopup(true);
  };

  const handleClosePopup = () => {
    setMedia({ mediaType: "", mediaSrc: "" });
    setShowMediaPopup(false);
  };

  return (
    <>
      <MediaPopup
        show={showMediaPopup}
        handleClose={handleClosePopup}
        type={media.mediaType}
        src={media.mediaSrc}
      />

      <Offcanvas
        show={editCoach1}
        onHide={() => setEditCoach1(false)}
        className="w-100"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h3 className="modal-title text-white" id="#gridSystemModal">
            {props.Title}
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => setEditCoach1(false)}
          >
            <i className="fa-solid fa-xmark color-white"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <Formik
              initialValues={formData || initialValues} // Use formData if available, otherwise use initialValues
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form onSubmit={(e) => handleSubmit(e, values)}>
                  <div className="row">
                    <div className="col-12 col-md-3 dz-default dlab-message upload-img">
                      {profile && (
                        <div className="mb-2">
                          <img
                            src={profile}
                            alt="Selected File"
                            className="cursor-pointer img-fluid"
                            onClick={() => handleShowPopup("image", profile)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column mb-3">
                      <label>Profile Picture</label>
                      <Field
                        type="file"
                        name="profileImg"
                        className="form-control cursor-pointer"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            const fileSize = file.size / 1024; // size in KB
                            // if (fileSize > 200) {
                            //   toast.error("File size exceeds 200 KB");
                            //   return;
                            // }

                            const reader = new FileReader();
                            reader.onload = () => {
                              const img = new Image();
                              img.src = reader.result;

                              img.onload = () => {
                                // const { width, height } = img;
                                // if (
                                //   width >= 290 &&
                                //   width <= 300 &&
                                //   height >= 290 &&
                                //   height <= 300
                                // ) {
                                setProfile(reader.result);
                                setSelectedFile(file);
                                // } else {
                                //   toast.error(
                                //     "Image dimensions must be between 290x290 and 300x300 pixels"
                                //   );
                                // }
                              };
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                      <p className="text-warning fs-12 mt-1 text-wrap">
                        Height & Width should be between 290 - 300 pixels & only
                        GIF, JPG, JPEG, PNG, or SVG images up to 200 KB.{" "}
                      </p>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="referalCode" className="form-label">
                        Referral Code
                      </label>
                      <Select
                        id="referralCode"
                        name="referralCode"
                        options={referalCodeList}
                        onChange={(selectedOption) =>
                          setFieldValue("referralCode", selectedOption)
                        }
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="phone" className="form-label">
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="phone"
                        className="form-control"
                        id="phone"
                        name="phone"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-12 mb-3">
                      {/* <label htmlFor="bio" className="form-label">
                      Bio
                    </label> */}
                      {/* <CKEditor
                      editor={ClassicEditor}
                      data={formData?.bio} // Use data prop instead of value
                      onChange={(event, editor) => {
                        const data = editor?.getData();
                        setFormData((prevState) => ({
                          ...prevState,
                          bio: data,
                        }));
                      }}
                    /> */}
                      <TextEditor
                        content={formData?.bio}
                        setContent={(newContent) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            bio: newContent,
                          }))
                        }
                        title={
                          "Tell us about your coaching experience, background, and what makes you unique."
                        }
                      />
                      <ErrorMessage
                        name="bio"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <h3 className="profile-head">Sport Details</h3>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="ageYouCoach" className="form-label">
                        Age Group
                      </label>
                      <Select
                        id="ageYouCoach"
                        name="ageYouCoach"
                        options={ageOptions}
                        isMulti
                        value={values.ageYouCoach}
                        onChange={(selectedOptions) =>
                          setFieldValue("ageYouCoach", selectedOptions)
                        }
                        classNamePrefix="select"
                      />
                      {ageGroupError && (
                        <p className="text-danger">{ageGroupError}</p>
                      )}
                      {touched.ageYouCoach && errors.ageYouCoach && (
                        <p className="text-danger">{errors.ageYouCoach}</p>
                      )}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="genderYouCoach" className="form-label">
                        Gender You Coach <span className="text-danger">*</span>
                      </label>
                      <Select
                        id="genderYouCoach"
                        name="genderYouCoach"
                        options={[
                          { value: "Male", label: "Male" },
                          { value: "Female", label: "Female" },
                          { value: "Both", label: "Both" },
                        ]}
                        value={values.genderYouCoach}
                        onChange={(selectedOptions) =>
                          setFieldValue("genderYouCoach", selectedOptions)
                        }
                        classNamePrefix="select"
                      />
                      <ErrorMessage
                        name="genderYouCoach"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="state" className="form-label">
                        Primary State You Serve{" "}
                        <span className="text-danger">*</span>
                      </label>

                      <Select
                        id="state"
                        name="state"
                        options={states}
                        value={values.state}
                        classNamePrefix="select"
                        onChange={handleStateChange}
                      />
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="city" className="form-label">
                        Select the neighboring cities you serve.
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              Don’t see your exact city? Choose the closest ones
                              to connect with athletes and organizations in your
                              region
                            </Tooltip>
                          }
                        >
                          <Info size={22} />
                        </OverlayTrigger>
                        <span className="text-danger">*</span>
                      </label>

                      <Select
                        id="city"
                        name="city"
                        options={cities}
                        value={values.city}
                        classNamePrefix="select"
                        isMulti
                        onChange={handleCityChange}
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="sportsId" className="form-label">
                        Primary Sport <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        id="sportsId"
                        name="sportsId"
                        value={values.sportsId}
                        onChange={(e) => {
                          e.preventDefault();
                          setFieldValue("sportsId", e.target.value);
                          setFieldValue("coachSpecialty", "");
                          setSelectedSportIDs(e.target.value);
                        }}
                      >
                        <option value="">Select a sport</option>
                        {sportData &&
                          sportData?.data?.map((sport) => (
                            <option key={sport.id} value={sport.id}>
                              {sport.sportName}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage
                        name="sportsId"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="coachSpecialty" className="form-label">
                        Your Expertise <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="coachSpecialty"
                        options={specialityData?.map((speciality) => ({
                          value: speciality.id,
                          label: speciality.specialityTitle,
                        }))}
                        classNamePrefix="react-select"
                        id="coachSpecialty"
                        onChange={(option) => {
                          setFieldValue("coachSpecialty", option); // For single select, we set the selected option directly
                        }}
                        value={values.coachSpecialty}
                      />
                      <ErrorMessage
                        name="coachSpecialty"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-12 mb-3">
                    <TextEditor
                      content={formData?.achievements}
                      setContent={(newContent) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          achievements: newContent,
                        }))
                      }
                      title={
                        "Share your coaching achievements and what excites you about connecting with athletes here."
                      }
                    />
                    <ErrorMessage
                      name="achievements"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <h3 className="profile-head">Gallery Details</h3>

                  <div className="pl-25">
                    <label htmlFor="additionalImage" className="form-label">
                      Gallery Image
                    </label>
                    <p className="text-danger">
                      Image dimensions must be between 290x290 and 300x300
                      pixels
                    </p>
                    <p className="text-danger">File Size under 250KB</p>
                    <div className="row mt-3">
                      {headshotImages &&
                        headshotImages.length > 0 &&
                        headshotImages.map((image, index) => (
                          <div key={index} className="col-xl-4 mb-3 p-2 d-grid">
                            <img
                              src={image.fileLocation}
                              alt={`Headshot Image ${index + 1}`}
                              style={{
                                width: "200px",
                                height: "225px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleShowPopup("image", image.fileLocation)
                              }
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-md mt-2"
                              onClick={() => handleDeleteAdditionalImage(index)}
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                      {[...Array(Math.max(0, 6 - headshotImages.length))].map(
                        (_, index) => (
                          <div key={index} className="col-xl-4 mb-3 p-2 d-grid">
                            <div className="d-flex align-items-center">
                              <input
                                type="file"
                                className="file-upload-input visually-hidden"
                                id={`additionalImage${
                                  headshotImages.length + index
                                }`}
                                name={`additionalImage${
                                  headshotImages.length + index
                                }`}
                                onChange={(e) =>
                                  handleFileSelection(
                                    e,
                                    headshotImages.length + index
                                  )
                                }
                              />
                              <img
                                src={
                                  selectedFiles[headshotImages.length + index]
                                    ?.fileURL || noimage
                                }
                                onClick={() =>
                                  handleShowPopup(
                                    "image",
                                    selectedFiles[headshotImages.length + index]
                                      ?.fileURL || noimage
                                  )
                                }
                                alt="No Image Available"
                                style={{
                                  width: "200px",
                                  height: "225px",
                                  cursor: "pointer",
                                }}
                              />
                              <label
                                htmlFor={`additionalImage${
                                  headshotImages.length + index
                                }`}
                                className="btn btn-primary btn-sm mt-2"
                                style={{ height: "max-content" }}
                              >
                                +
                              </label>
                            </div>
                            <button
                              width="80"
                              type="button"
                              className="btn btn-primary btn-md mt-2"
                              onClick={() =>
                                handleSave(headshotImages.length + index)
                              }
                            >
                              Save
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="col-xl-12 mb-3 pl-25">
                    {/* <label htmlFor="videoFile" className="form-label">
                      Video
                    </label>

                    <div className="file-upload-wrapper pl-25">
                      <input
                        type="file"
                        className="file-upload-input"
                        id="videoFile"
                        name="videoFile"
                        multiple
                        accept="video/*" // Accept only video files
                        onChange={handleVideoUpload}
                      />
                      <label
                        htmlFor="videoFile"
                        className="file-upload-label"
                      >
                        +
                      </label>
                    </div>
                    <p className="text-danger">
                      Video Duration should be maximum 30 seconds
                    </p>
                    <p className="text-danger">Video Size under 250KB</p>
                    {validationErrors.length > 0 && (
                      <div className="validation-errors">
                        {validationErrors.map((error, index) => (
                          <p key={index} className="text-danger">
                            {error}
                          </p>
                        ))}
                      </div>
                    )}
                    <div className="col-xl-12 mb-3 p-2 d-flex">
                      {videoPreviews.length > 0 &&
                        videoPreviews.map((video, index) => (
                          <div key={index} className="video-container p-2">
                            <video controls width="300" height="300">
                              <source src={video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        ))}
                      {videos.length > 0 &&
                        videos.map((video) => (
                          <div key={video.id} className="video-container p-2">
                            <video controls width="300" height="300">
                              <source
                                src={video.fileLocation}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                            {!video.isApproved && (
                              <div className="video-actions d-flex">
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-md mt-2 me-1"
                                    onClick={() => handleApprove(video.id)}
                                  >
                                    Approve
                                  </button>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-md mt-2"
                                    onClick={() => handleReject(video.id)}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div> */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="videoTitle">Video Title</label>
                          <Field
                            name="videoTitle"
                            type="text"
                            className="form-control"
                            placeholder="Enter Video Title"
                            value={videosData.videoTitle}
                            onChange={handleVideoDataChange}
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="videoUrl">Video URL</label>
                          <Field
                            name="videoUrl"
                            type="text"
                            className="form-control"
                            placeholder="Enter Video URL"
                            value={videosData?.videoUrl}
                            onChange={handleVideoDataChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-3 d-flex justify-content-center">
                      {Boolean(editVideoId) ? (
                        <Button onClick={handleUpdateVideoData}>Update</Button>
                      ) : (
                        videosDataList?.length < 3 && (
                          <Button onClick={handleAddVideoData}>Add</Button>
                        )
                      )}
                    </div>

                    <div className="col-9 mb-3 d-flex flex-column">
                      {videosDataList?.length > 0 && (
                        <>
                          <h5>Videos Details</h5>
                          <ul className="d-flex flex-column">
                            {videosDataList?.map((each, index) => (
                              <li
                                className="d-flex gap-2 align-items-center p-0 justify-content-between"
                                key={each.id}
                              >
                                <div className="mb-3">
                                  <h6 className="p-0 m-0">{each.videoTitle}</h6>
                                  <a
                                    href={each.videoLink}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="text-wrap">
                                      {each.videoLink}
                                    </span>
                                  </a>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  <Pencil
                                    size={20}
                                    className="cursor-pointer"
                                    onClick={() => handleEditVideoData(each.id)}
                                  />
                                  <Trash2
                                    size={20}
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleDeleteVideoData(each.id)
                                    }
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>

                  <h3 className="profile-head">Social Media</h3>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="twitterLink" className="form-label">
                        X Profile Link
                        {formData?.twitterLink && (
                          <a
                            href={`${formData?.twitterLink}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <SquareArrowOutUpRight
                              size={15}
                              className="cursor-pointer mx-2"
                            />
                          </a>
                        )}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="twitterLink"
                        name="twitterLink"
                      />
                      <ErrorMessage
                        name="twitterLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="instagramLink" className="form-label">
                        Instagram Profile Link
                        {formData?.instagramLink && (
                          <a
                            href={`${formData?.instagramLink}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <SquareArrowOutUpRight
                              size={15}
                              className="cursor-pointer mx-2"
                            />
                          </a>
                        )}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="instagramLink"
                        name="instagramLink"
                      />
                      <ErrorMessage
                        name="instagramLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="websiteLink" className="form-label">
                        Website Link
                        {formData?.websiteLink && (
                          <a
                            href={`${formData?.websiteLink}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <SquareArrowOutUpRight
                              size={15}
                              className="cursor-pointer mx-2"
                            />
                          </a>
                        )}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="websiteLink"
                        name="websiteLink"
                      />
                      <ErrorMessage
                        name="websiteLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor="termsAcceptedDate"
                          className="form-label"
                        >
                          Last Terms and Conditions Accepted Date
                        </label>
                        <div
                          className="terms-accepted-date"
                          style={{
                            fontWeight: "bold", // Makes the text bold
                            color: "#333", // Sets the text color
                            padding: "10px", // Adds padding inside the box
                            border: "1px solid #ccc", // Adds a border to create a box
                            borderRadius: "4px", // Rounds the corners of the box
                            backgroundColor: "#f9f9f9", // Light background color for the box
                            whiteSpace: "nowrap", // Ensures text doesn't wrap
                            overflow: "hidden", // Prevents overflow of long text
                            textOverflow: "ellipsis", // Shows ellipsis if text is too long
                          }}
                        >
                          {termsAcceptedDate
                            ? moment
                                .utc(termsAcceptedDate)
                                .format("MMM DD, YYYY hh:mm:ss A")
                            : "No data available"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <h3 className="profile-head">Profile Status</h3>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="isApprove" className="form-label">
                        Approval Status
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        id="isApprove"
                        name="isApprove"
                      >
                        <option value="Approve">Approve</option>
                        <option value="Pending">Pending</option>
                        <option value="Reject">Reject</option>
                      </Field>

                      <ErrorMessage
                        name="isApprove"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="isActive" className="form-label">
                        Publish Status
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        id="isActive"
                        name="isActive"
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Field>

                      <ErrorMessage
                        name="isActive"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary me-1"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <Link
                      to="#"
                      onClick={() => setEditCoach1(false)}
                      className="btn btn-secondary"
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default EditCoachOffcanvas;
