import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import NoImage from "../../../../images/no-image.jpg";
import PageTitle from "../../../layouts/PageTitle";
import TextEditor from "../../Common/TextEditor";

const ContactInfo = () => {
  const [title, setTitle] = useState("");
  const [sectionContent, setSectionContent] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [files, setFiles] = useState(Array.from({ length: 5 }).map(() => null));
  const [file, setFile] = useState(null);

  const fetchPageData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/3`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const cmssections = response.data.data.cmssections;
      const section = cmssections.find((section) => section.id === 15);
      if (section) {
        setTitle(section.title || "");
        setSectionContent(section.description || "");
        setShortDescription(section.shortDescription || "");
      }

      const cmsgalleries = response.data.data.cmsgalleries;
      const banner = cmsgalleries.find((item) => item.cmssectionId === 15);
      console.log(banner);
      if (banner) {
        setFile(banner.fileLocation);
      }
      console.log(file);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updatePageData = async (title, description, shortDescription) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const fetchedData = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/3`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      // Find the section with id 2
      const sectionToUpdate = fetchedData.data.data.cmssections.find(
        (section) => section.id === 15
      );

      if (!sectionToUpdate) {
        console.error("Section with id 11 not found");
        return;
      }

      // Update the section
      sectionToUpdate.title = title;
      sectionToUpdate.description = description;
      sectionToUpdate.shortDescription = shortDescription;

      // Create the updated data object
      const updatedData = {
        ...fetchedData.data,
        cmssections: [sectionToUpdate], // Only include the updated section
      };

      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updatepage`,
        updatedData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        swal("Update successful", { icon: "success" });
      } else {
        swal("Failed to update", { icon: "error" });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      swal("Failed to update", { icon: "error" });
    }
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  const handleSave = () => {
    updatePageData(title, sectionContent, shortDescription);
  };

  const fileHandler = (event, index) => {
    const selectedFile = event.target.files[0];
    console.log("Selected File:", selectedFile);

    const image = new Image();

    // Load selected image for validation
    image.src = URL.createObjectURL(selectedFile);

    // Validate image dimensions
    image.onload = function () {
      // if (
      //   image.width >= 390 &&
      //   image.width <= 400 &&
      //   image.height >= 310 &&
      //   image.height <= 320
      // ) {
        // Image meets the required dimensions
        const newFiles = [...files];
        newFiles[index] = selectedFile;
        setFiles(newFiles);

        // Set the selected image as the preview
        setFile(URL.createObjectURL(selectedFile));
      // } else {
      //   // Image dimensions are not within the required range, show error message
      //   swal(
      //     "Invalid Image Dimensions",
      //     "Please select an image with width between 390 to 400 and height between 310 to 320 pixels.",
      //     "error"
      //   );
      // }
    };
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="h-auto">
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-lg-11">
                    <PageTitle
                      activeMenu="Contact Section"
                      motherMenu="Contact"
                    />
                  </div>
                  <div className="col-lg-1">
                    <div className="content-title border-bot">
                      <button
                        type="button"
                        className="btn btn-secondary light my-2"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>

                <div className="  cm-content-body publish-content form excerpt">
                  <div className="card-body">
                    <div className="avatar-upload d-flex align-items-center justify-content-center">
                      <div className=" col-xl-4 ">
                        <div className="">
                          <div className="avatar-preview">
                            <div
                              id="imagePreview"
                              style={{
                                backgroundImage: file
                                  ? `url(${file})`
                                  : `url(${NoImage})`,
                              }}
                              width={200}
                            ></div>
                          </div>
                        </div>
                        <div className="change-btn d-flex align-items-center flex-wrap justify-content-center">
                          <input
                            type="file"
                            onChange={fileHandler}
                            id="imageUpload"
                            className="form-control d-none"
                            // onClick={(event) => setFile(event.target.value)}
                          />
                          <label
                            for="imageUpload"
                            className="btn btn-primary ms-0"
                          >
                            +
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <TextEditor
                  content={title}
                  setContent={setTitle}
                  title={"Title"}
                />
                {/* <TextEditor
                  content={shortDescription}
                  setContent={setShortDescription}
                 title="Short Description"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
