import { lazy, Suspense, useEffect, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate, useParams, Outlet } from "react-router-dom";
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import 'react-toastify/dist/ReactToastify.css';
import "./css/style.css";
import { ThemeContext } from "./context/ThemeContext";
import Index from "./jsx";
import Nav from "./jsx/layouts/nav";
import Footer from "./jsx/layouts/Footer";
import PublicRoute from "./PublicRoute";

import Error400 from "./jsx/pages/Error400";
import Error403 from "./jsx/pages/Error403";
import Error404 from "./jsx/pages/Error404";
import Error500 from "./jsx/pages/Error500";
import Error503 from "./jsx/pages/Error503";
import { components } from "react-select";
// import Announcement from "./jsx/components/Cms/Announcement";

const Home = lazy(() => import("../src/jsx/components/Dashboard/Home"));
const DashboardDark = lazy(() => import("../src/jsx/components/Dashboard/DashboardDark"));
const Performance = lazy(() => import("../src/jsx/components/Dashboard/Performance"));
const Projects = lazy(() => import("../src/jsx/components/Dashboard/Projects"));
const TaskSummary = lazy(() => import("../src/jsx/components/Dashboard/TaskSummary"));
const HomeBlog = lazy(() => import("../src/jsx/components/Dashboard/Blog"));
const SubscriptionSetup = lazy(() => import("../src/jsx/components/Dashboard/SubscriptionSetup"));
const Report = lazy(() => import("../src/jsx/components/Dashboard/Report"));
const Finance = lazy(() => import("../src/jsx/components/Dashboard/Finance"));
const Messenger = lazy(() => import("../src/jsx/components/Dashboard/Messenger"));
const Athlete = lazy(() => import("../src/jsx/components/Dashboard/Athlete"));
const Sport = lazy(() => import("../src/jsx/components/Dashboard/sport"));
const Speciality = lazy(() => import("./jsx/components/Dashboard/specialty"));
const Profile = lazy(() => import("../src/jsx/components/Dashboard/Profile"));
const Coach = lazy(() => import("../src/jsx/components/Dashboard/Coach-profile"));
const Club = lazy(() => import("../src/jsx/components/Dashboard/Club-profile"));
const PromoCode = lazy(() => import("../src/jsx/components/Dashboard/PromoCode"));
const ReferalCode = lazy(() => import("./jsx/components/Dashboard/referralCode/ReferalCode"));
const BillingState = lazy(() => import("../src/jsx/components/Dashboard/BillingState"));
const PromoReport = lazy(() => import("../src/jsx/components/Dashboard/PromoReport"));
const ClubReport = lazy(() => import("../src/jsx/components/Dashboard/ClubReport"));
const AthleteReport = lazy(() => import("../src/jsx/components/Dashboard/AthleteReport"));
const ContactList = lazy(() => import("../src/jsx/components/Dashboard/ContactList"));
const ChangePassword = lazy(() => import("./jsx/components/Dashboard/ChangePassword"));
const AdminProfile = lazy(() => import("./jsx/components/Dashboard/AdminProfile"));
const LockScreen = lazy(() => import("./jsx/pages/LockScreen"));
const Content = lazy(() => import('./jsx/components/Cms/Content'));
const Menu = lazy(() => import('./jsx/components/Cms/Menu'));
const EmailTemplate = lazy(() => import('./jsx/components/Cms/EmailTemplate'));
const CmsBlog = lazy(() => import('./jsx/components/Cms/Blog'));
const ContentAdd = lazy(() => import('./jsx/components/Cms/ContentAdd'));
const HomePage = lazy(() => import('./jsx/components/Cms/HomePage'));
const TermsConditions = lazy(() => import('./jsx/components/Cms/TermsConditions'));
const PrivacyPolicy = lazy(() => import('./jsx/components/Cms/PrivacyPolicy'));
const Contact = lazy(() => import('./jsx/components/Cms/ContactCms'));
const AthleteIntake = lazy(() => import('./jsx/components/Cms/AthleteIntakeCms'));
const AboutPage = lazy(() => import('./jsx/components/Cms/AboutPage'));
const AddMail = lazy(() => import('./jsx/components/Cms/AddMail'));
const AddBlog = lazy(() => import('./jsx/components/Cms/AddBlog'));
const BlogCategory = lazy(() => import('./jsx/components/Cms/BlogCategory'));
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const Login = lazy(() => new Promise((resolve) => {
  setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
}));
const ForgotPassword = lazy(() => new Promise((resolve) => {
  setTimeout(() => resolve(import("./jsx/pages/ForgotPassword")), 500);
}));
const Announcement = lazy(() => import('./jsx/components/Cms/Announcements'));
const AthleteAnnouncement = lazy(() => import('./jsx/components/Cms/AthleteAnnouncement'));
const CoachAnnouncement = lazy(() => import('./jsx/components/Cms/CoachAnnouncement'));
const AcademyAnnouncement = lazy(() => import('./jsx/components/Cms/AcademyAnnouncement'));
const Generalannouncement = lazy(() => import('./jsx/components/Cms/GeneralAnnouncement'));
const AddAnnouncement = lazy(() => import('./jsx/components/Cms/Addannouncement'));
const Addannounceathlete = lazy(() => import('./jsx/components/Cms/AddAnnoucement/athlete'));
const Addannouncecoach = lazy(() => import('./jsx/components/Cms/AddAnnoucement/Coach'));
const Addannounceclub = lazy(() => import('./jsx/components/Cms/AddAnnoucement/club'));
const AppBanners = lazy(() => import('./jsx/components/Cms/MobileBanner'));
const allroutes = [

  { url: "", component: <Home /> },
  { url: "dashboard", component: <Home /> },
  { url: "dashboard-dark", component: <DashboardDark /> },
  { url: "promo-code", component: <PromoCode /> },
  { url: "referal-code", component: <ReferalCode /> },
  { url: "performance", component: <Performance /> },
  { url: "project", component: <Projects /> },
  { url: "task-summary", component: <TaskSummary /> },
  { url: "blog-1", component: <HomeBlog /> },
  { url: "subscription-setup", component: <SubscriptionSetup /> },
  { url: "reports", component: <Report /> },
  { url: "finance", component: <Finance /> },
  { url: "athlete", component: <Athlete /> },
  { url: "sport", component: <Sport /> },
  { url: "speciality", component: <Speciality /> },
  { url: "messenger", component: <Messenger /> },
  { url: "coach-profile", component: <Coach /> },
  { url: "club-profile", component: <Club /> },
  { url: "profile", component: <Profile /> },
  { url: "billing-state", component: <BillingState /> },
  { url: "athlete-report", component: <AthleteReport /> },
  { url: "club-report", component: <ClubReport /> },
  { url: "promo-report", component: <PromoReport /> },
  { url: "contact", component: <ContactList /> },
  { url: 'content', component: <Content /> },
  { url: 'menu', component: <Menu /> },
  { url: 'email-template', component: <EmailTemplate /> },
  { url: 'blog', component: <CmsBlog /> },
  { url: "athlete-intake", component: <AthleteIntake /> },
  { url: "announcement", component: < Announcement /> },
  { url: "add-announcement", component: <AddAnnouncement /> },
  { url: "add-athleteannouncement", component: <Addannounceathlete /> },
  { url: "add-coachannouncement", component: <Addannouncecoach /> },
  { url: "add-clubannouncement", component: <Addannounceclub /> },
  { url: "athlete-announcement", component: <AthleteAnnouncement /> },
  { url: "coach-announcement", component: <CoachAnnouncement /> },
  { url: "academy-announcement", component: <AcademyAnnouncement /> },
  { url: "general-announcement", component: <Generalannouncement /> },
  { url: "app-banner", component: <AppBanners /> },
  { url: 'add-homepage', component: <HomePage /> },
  { url: 'terms-conditions', component: <TermsConditions /> },
  { url: 'privacy-policy', component: <PrivacyPolicy /> },
  { url: 'about-page', component: <AboutPage /> },
  { url: 'contact-us', component: <Contact /> },
  { url: 'add-content', component: <ContentAdd /> },
  { url: 'add-email', component: <AddMail /> },
  { url: 'add-blog', component: <AddBlog /> },
  { url: 'blog-category', component: <BlogCategory /> },
  { url: "change-password", component: <ChangePassword /> },
  { url: "admin-profile", component: <AdminProfile /> }
];

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, []);

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-passwprd" element={<ForgotPassword />} />
      <Route path="/page-register" element={<SignUp />} />
      <Route path='/page-lock-screen' element={<LockScreen />} />
      <Route path='/page-error-400' element={<Error400 />} />
      <Route path='/page-error-403' element={<Error403 />} />
      <Route path='/page-error-404' element={<Error404 />} />
      <Route path='/page-error-500' element={<Error500 />} />
      <Route path='/page-error-503' element={<Error503 />} />
      <Route element={<MainLayout />}>
        {allroutes.map((data, i) => (
          <Route
            key={i}
            exact
            path={`${data.url}`}
            element={data.component}
          />
        ))}
      </Route>
    </Routes>
  );

  return (
    <div className="vh-100">
      <Suspense fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }>
        {props.isAuthenticated ? <Index /> : routeblog}
      </Suspense>
    </div>
  );
}

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
