import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert";
import NoImage from "../../../../images/no-image.jpg";
import TextEditor from "../../Common/TextEditor";

const Oursports = () => {
  const [title, setTitle] = useState("");
  const [sectionContent, setSectionContent] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [banners, setBanners] = useState([]);
  const [localImages, setLocalImages] = useState([]);
  const [newFile, setNewFile] = useState(null);
  const [changedBanners, setChangedBanners] = useState([]);
  const [fileTitle, setFileTitle] = useState("");
  const [hideSection, setHideSection] = useState(true);

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/1`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const cmssections = response.data.data.cmssections;
      const section = cmssections.find((section) => section.id === 4);
      if (section) {
        setTitle(section.title || "");
        setSectionContent(section.description || "");
        setShortDescription(section.shortDescription || "");
        setHideSection(section.isPublish)
      }

      const cmsgalleries = response.data.data.cmsgalleries;
      const bannerData = cmsgalleries.filter(
        (item) => item.cmssectionId === 20
      );

      const newBanners = bannerData.map((banner) => ({
        fileTitle: banner.fileTitle || "",
        fileDescription: banner.fileDescription || "",
        fileLocation: banner.fileLocation,
        id: banner.id,
        cmId: banner.cmId,
      }));

      setBanners(newBanners);
      setLocalImages(Array(newBanners.length).fill(null));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updatePageData = async (title, description, shortDescription,isPublished) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const fetchedData = await axios.get(
        `${process.env.REACT_APP_CONTENT_API_URI}/getpage/1`,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const sectionToUpdate = fetchedData.data.data.cmssections.find(
        (section) => section.id === 4
      );

      if (!sectionToUpdate) {
        console.error("Section with id 4 not found");
        return;
      }

      sectionToUpdate.title = title;
      sectionToUpdate.shortDescription = shortDescription;
      sectionToUpdate.isPublish=isPublished

      const updatedData = {
        ...fetchedData.data,
        cmssections: fetchedData.data.data.cmssections.map((section) =>
          section.id === 4 ? sectionToUpdate : section
        ),
      };

      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updatepage`,
        updatedData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        swal("Update successful", { icon: "success" });
        fetchPageData()
      } else {
        swal("Failed to update", { icon: "error" });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      swal("Failed to update", { icon: "error" });
    }
  };

  const getImageUrl = (banner, localImage) => {
    if (localImage) {
      return URL.createObjectURL(localImage);
    } else if (banner) {
      return banner.fileLocation;
    } else {
      return NoImage;
    }
  };

  const fileHandler = (event, index) => {
    const selectedFile = event.target.files[0];
    console.log("Selected File:", selectedFile);

    const img = new Image();
    img.src = URL.createObjectURL(selectedFile);

    img.onload = () => {
      if (img.width < 200 || img.width > 250) {
        swal(
          "Invalid Image Dimensions",
          "Please select an image with width between 200 to 250.",
          "error"
        );
      } else {
        const updatedLocalImages = [...localImages];
        updatedLocalImages[index] = selectedFile;
        setLocalImages(updatedLocalImages);

        // Track changed banners
        const updatedChangedBanners = [...changedBanners];
        if (!updatedChangedBanners.includes(banners[index])) {
          updatedChangedBanners.push({
            ...banners[index],
            fileLocation: selectedFile,
          });
        }
        setChangedBanners(updatedChangedBanners);
      }
    };
  };

  const handleNewFileChange = (event) => {
    setNewFile(event.target.files[0]);
  };
  const handleFileTitleChange = (event) => {
    setFileTitle(event.target.value);
  };
  const handleAddImage = async () => {
    if (!newFile) {
      swal("Please select a file to upload.", { icon: "warning" });
      return;
    }

    if (!fileTitle) {
      swal("Please enter a title for the file.", { icon: "warning" });
      return;
    }

    const formData = new FormData();
    formData.append("fileType", "sport Image");
    formData.append("fileTitle", fileTitle);
    formData.append("fileDescription", "");
    formData.append("cmId", 1);
    formData.append("cmssectionId", 20);
    formData.append("fileLocation", newFile);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/addsectionimage`,
        formData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        fetchPageData();
        const updatedBanners = [
          ...banners,
          {
            fileTitle: fileTitle,
            fileDescription: "",
            fileLocation: response.data.fileLocation,
          },
        ];
        setBanners(updatedBanners);
        setLocalImages([...localImages, newFile]);
        setNewFile(null);
        setFileTitle("");
        swal("Image uploaded successfully", { icon: "success" });
      } else {
        swal("Failed to upload image", { icon: "error" });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      swal("Failed to upload image", { icon: "error" });
    }
  };
  const removeImage = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/deletepageimage/${id}`,
        {},
        { headers }
      );

      if (response.status === 200) {
        swal("Success", "Image removed successfully", "success");
        fetchPageData();
      } else {
        swal("Error", "Failed to remove Image", "error");
      }
    } catch (error) {
      swal("Error", "Failed to remove Image", "error");
      console.error("Error removing Image:", error);
    }
  };

  const handleSave = async () => {
    await updatePageData(title, sectionContent, shortDescription, hideSection);

    if (changedBanners.length > 0) {
      await updateExactFiles(changedBanners);
    }
  };

  const updateExactFiles = async (changedBanners) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      console.log("Image", changedBanners);

      const formData = new FormData();
      formData.append(
        "cmsgalleries",
        JSON.stringify(
          changedBanners.map((banner) => ({
            id: banner.id,
            cmId: banner.cmId,
          }))
        )
      );
      changedBanners.forEach((banner) => {
        formData.append("fileLocation", banner.fileLocation);
      });
      const response = await axios.post(
        `${process.env.REACT_APP_CONTENT_API_URI}/updateexactfiles`,
        formData,
        {
          headers: {
            authorization: token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Exact files updated successfully");
      } else {
        console.error("Failed to update exact files");
      }
    } catch (error) {
      console.error("Error updating exact files:", error);
    }
  };

  const handleToggleSection = async(event) =>{
    setHideSection(event.target.checked)
    await updatePageData(title, sectionContent, shortDescription, event.target.checked);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="h-auto">
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-11">
                    <div className="content-title border-bot d-flex">
                      <h4 className="d-flex align-items-center">Our Sports</h4>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id={`tooltip-dimensions-BANNER`}>
                            Image dimensions - 200-250px in width.
                            <br></br>File Size should be under 200KB
                          </Tooltip>
                        }
                      >
                        <div className="d-flex  mx-2 align-items-center">
                          <i class="bi bi-question-circle"></i>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="col-1">
                    <div className="content-title border-bot">
                      <Form.Check
                        type="switch"
                        id={`welcome-section`}
                        checked={hideSection}
                        onChange={handleToggleSection}
                      />
                    </div>
                  </div>
                </div>
                {hideSection && (
                  <>
                    <TextEditor
                      content={title}
                      setContent={setTitle}
                      title={"Title"}
                    />
                    <TextEditor
                      content={shortDescription}
                      setContent={setShortDescription}
                      title={"Description"}
                    />
                    <div className="card-body">
                      <div className="avatar-upload d-flex align-items-center justify-content-center flex-wrap">
                        {banners.map((banner, index) => (
                          <div key={index} className="col-xl-3 mb-3">
                            <div className="avatar-preview d-flex justify-content-center">
                              <div
                                id={`imagePreview${index}`}
                                style={{
                                  backgroundImage: `url(${getImageUrl(
                                    banner,
                                    localImages[index]
                                  )})`,
                                  width: "200px",
                                  height: "250px",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              ></div>
                            </div>
                            <div className="change-btn d-flex flex-column align-items-center justify-content-center">
                              <input
                                type="file"
                                onChange={(e) => fileHandler(e, index)}
                                id={`Oursports${index}`}
                                className="form-control d-none"
                                accept="image/*"
                              />
                              <div>
                                <h5 className="mt-2 mb-4">
                                  {banner.fileTitle}
                                </h5>
                              </div>
                              <div className="d-flex">
                                {/* <label
                                                            htmlFor={`Oursports${index}`}
                                                            className="btn btn-primary ms-0 mb-0"
                                                        >
                                                            +
                                                        </label> */}
                                <button
                                  className="btn btn-danger mt-0"
                                  onClick={() => removeImage(banner.id)}
                                  style={{ marginLeft: "10px" }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="col-xl-3 mb-3">
                          <div className="avatar-preview d-flex justify-content-center">
                            <div
                              id={`imagePreview${banners.length}`}
                              style={{
                                backgroundImage: `url(${
                                  newFile
                                    ? URL.createObjectURL(newFile)
                                    : NoImage
                                })`,
                                width: "200px",
                                height: "250px",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          </div>
                          <div className="content-title border-bot text-center">
                            <input
                              type="file"
                              onChange={handleNewFileChange}
                              id="addImageFile3"
                              className="form-control d-none"
                              accept="image/*"
                            />
                            <div>
                              <input
                                type="text"
                                value={fileTitle}
                                onChange={handleFileTitleChange}
                                placeholder="Enter file title"
                                className="form-control my-2"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="addImageFile3"
                                className="btn btn-secondary light my-2 "
                                style={{ marginRight: "15px" }}
                              >
                                +
                              </label>
                              <button
                                type="button"
                                className="btn btn-secondary light my-2"
                                onClick={handleAddImage}
                              >
                                save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center w-100">
                      <button
                        type="button"
                        className="btn btn-primary w-90px"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Oursports;
